import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/terms.css";
import { useSelector } from "react-redux";

function TermCondition() {
  const pagesData = useSelector((state) => state.AuthReducer.pagesData);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">TERMS & CONDITIONS</h2>
            <ul className="breadcrumbs">
              <li>
                <a href="index.php">HOME PAGE</a>
              </li>
              <li className="active">TERMS & CONDITIONS</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            {pagesData?.map((item, index) => {
              return (
                <>
                  {item?.slug == "terms-and-conditions" ? (
                    <div className="col-lg-12" key={index}>
                      <div className="content-wrapper">
                        <h5>{item?.page_title}</h5>

                        <div className="question-and-answer-wrapper">
                          <div className="answer-wrapper">
                            <p
                              className="productDesc"
                              dangerouslySetInnerHTML={{
                                __html: item?.content,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default TermCondition;
