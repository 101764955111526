import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/menu.css";
import {
	notFound,
	separator,
} from "../../constant";
import MenuCard from "../../components/Card/MenuCard";
import { GetAllMenu } from "../../network/Network";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuPdf from '../../assets/pdf/Mr. Stacks Main Menu.pdf'
import CatePdf from '../../assets/pdf/Mr. Stacks Catering Menu.pdf'
import { AiFillFilePdf } from 'react-icons/ai';

function Menu2() {
	const dispatch = useDispatch();
	const CategoriesDataRedux = useSelector(
		(state) => state.AuthReducer.categoriesData,
	);
	const allStates = useSelector((state) => state.CartReducer.cartData);
	const location = useLocation();
	let paramData = location?.state?.data;
	console.log(location?.state?.data);
	const [selectCate, setSelectCate] = useState(paramData);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<>
			<Header setSelectCate={setSelectCate} />
			{/* <!-- Universal Banner Sec Start Here --> */}
			<section className="universal_banner">
				<div className="container">
					<div className="page_details">
						<h2 className="title">Menu List</h2>
						<ul className="breadcrumbs">
							<li>
								<Link to="/">HOME PAGE</Link>
							</li>
							<li className="active">MENU</li>
						</ul>
					</div>
				</div>
			</section>
			{/* <!-- Universal Banner Sec End Here --> */}

			{/* <!-- Menus Sec Start Here --> */}
			<section className="menu_list">
				<div className="container-fluid">
					<div className="heading_wrapper">
						{/* <h5 className="sub-title">RCOMMENDATIONS</h5> */}
						{/* <h2 className="title"><a href={MenuPdf} target="_blank" >Restaurant Menu</a></h2>
                        <h2 className="title mt-5"><a href={CatePdf} target="_blank" >Catering Menu</a></h2> */}
						<div className="seprator">
							<figure>
								<img src={separator} className="img-fluid" />
							</figure>
						</div>
						<div className="row mt-5">
							<div className="col-md-6">
								<div>
									<a href={MenuPdf} target="_blank" >
										<AiFillFilePdf className="pdf-icon"/>
									</a>
									<div>
									<h3 className="title2"><a href={MenuPdf} target="_blank" >Restaurant Menu</a></h3>
									</div>
								</div>
							</div>
							<div className="col-md-6">
							<div>
									<a href={MenuPdf} target="_blank" >
										<AiFillFilePdf className="pdf-icon"/>
									</a>
									<div>
									<h3 className="title2"><a href={CatePdf} target="_blank" >Catering Menu </a></h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</section>
			{/* <!-- Menus Sec End Here --> */}
			<Footer />
		
		</>
	);
}

export default Menu2;
