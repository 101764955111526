import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/feature-jobs.css";
import { separator } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import {
  GetAllCareers,
  GetCareerDetails,
  PostResume,
} from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faFileLines } from "@fortawesome/free-solid-svg-icons";
import InputAutoComplete from "../../components/AuComplete/InputAutoComplete";

function Careers() {
	const navigate = useNavigate()
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [spinLoad, setSpinLoad] = useState(false);
  const [careerData, setCareerData] = useState([]);
  const [type, setType] = useState();

  const [mediaFile, setMediaFile] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  let currentUrl = window.location.href.split("/");
  console.log(currentUrl[4]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cateType, setCateType] = useState("");
  const [message, setMessage] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [statedata, setStateData] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const Cate = [
    {
      id: 1,
      name: "Waiter/Waitress",
    },
    {
      id: 2,
      name: "Host/Hostess",
    },
    {
      id: 3,
      name: "Busser/Dishwasher",
    },
    {
      id: 4,
      name: "Line Cook",
    },
  ];

  useEffect(() => {
    setSpinLoad(true);
    GetAllCareers(currentPage, type)
      .then((res) => {
        setCareerData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage, type]);



  useEffect(() => {
    setSpinLoad(true);
    GetCareerDetails(currentUrl[4])
      .then((res) => {
        setJobDetails(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e?.target?.files[0];
    reader.onloadend = () => {
      setMediaFile(file);
      console.log("media", file);
    };
    reader?.readAsDataURL(file);
  };

  const UploadResume = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(mediaFile);
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone 
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (firstName.length < 2 || firstName.length > 15) {
			toast.error("First name limit is min 3 and max 15 characters");
			setLoading(false);
			return;
		}
    if (lastName.length < 2 || lastName.length > 15) {
			toast.error("Last name limit is min 3 and max 15 characters");
			setLoading(false);
			return;
		}
    if (!cateType) {
      toast.error("Please select Position");
      setLoading(false);
      return;
    }
    if (!mediaFile) {
      toast.error("Please Upload Cv");
      setLoading(false);
      return;
    }
    if (phone.length < 10 || phone.length > 15) {
      toast.error(
        "Phone no must be greater than 10 and less than 15 characters."
      );
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("email", email);
    data.append("phone_no", phone);
    data.append("address", completeAddress);
    // data.append("city", city);
    // data.append("state", statedata);
    // data.append("postal_code", postalCode);
    data.append("type", cateType);
    data.append("message", message);
    // data.append("formated_address", message);
    //   data.append("career_id", jobDetails?.id);
    data.append("attach", mediaFile);
    PostResume(data)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setLoading(false);
        setMediaFile("");
        setIsOpenModal(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setCity("");
        setStateData("");
        setPostalCode("");
        setMessage("");
        setCateType("");
		navigate("/")
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">FEATURED JOBS</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">CAREERS</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {/* <!-- Feature Jobs Start Here --> */}
      <section className="feature_jobs">
        <div className="container">
          <div className="heading_wrapper">
            <h5 className="sub-title">WORKWITH US</h5>
            <h2 className="title">Opportunity awaits </h2>
            <div className="seprator">
              <figure>
                <img src={separator} className="img-fluid" />
              </figure>
            </div>
          </div>
          {/* <div className="filter_dv">
						<div className="showing_pages">
							<p>
								Showing {""}
								{careerData?.length}
								{""} results
							</p>
						</div>
						<div className="filters_btn">
							<div className="form-group">
								<select
									id="method"
									className="form-control"
									onChange={(e) => {
										setType(e.target.value);
									}}
								>
									<option selected="">Sort by (Default)</option>
									<option value={"asc"}>Aascending order</option>
									<option value={"dsc"}>Descending order</option>
								</select>
							</div>
							
						</div>
					</div>
					{spinLoad ? (
						<>
							<div className="m-4">
								<Skeleton count={5} />
							</div>
							<div className="m-4">
								<Skeleton count={5} />
							</div>
							<div className="m-4">
								<Skeleton count={5} />
							</div>
						</>
					) : (
						<>
							<div className="all_jobs">
								{careerData?.map((item, index) => {
									return (
										<>
											<div className="job_box" key={index}>
												<div className="img_box">
													<figure>
														<img src={item?.image} className="img-fluid" />
													</figure>
												</div>
												<div className="job_flex">
													<div className="job_details">
														<Link to={`/job-details/${item?.id}`}>
															<h4 className="title">
																{item?.title} ( indoor dining){" "}
																<span className="type">FEATURED</span>
															</h4>
														</Link>
														<p className="location">{item?.address}</p>
														<div className="all_details">
															<div className="icon_with_text">
																<figure>
																	<img src={icon1} className="img-fluid" />
																</figure>
																<h6>Kitchen</h6>
															</div>
															<div className="icon_with_text">
																<figure>
																	<img src={icon2} className="img-fluid" />
																</figure>
																<h6>{item?.country}</h6>
															</div>
															<div className="icon_with_text">
																<figure>
																	<img src={icon3} className="img-fluid" />
																</figure>
																<h6>{item?.expiry_date}</h6>
															</div>
															<div className="icon_with_text">
																<figure>
																	<img src={icon4} className="img-fluid" />
																</figure>
																<h6>{item?.package}</h6>
															</div>
														</div>
													</div>
													<div className="button-group">
														<button className="full_time">
															{item?.job_type}
														</button>
														{item?.is_urgent ? (
															<button className="urgent">Urgent</button>
														) : null}
													</div>
												</div>
											</div>
										</>
									);
								})}
							</div>
						</>
					)}
					{careerData?.length === 0 && (
						<h1 className="Not-found">No Job Founds</h1>
					)}
					{careerData?.length > 0 && (
						<div className="pagination-container paginations mt-5">
							<ReactPaginate
								previousLabel="<"
								nextLabel=">"
								breakLabel={"..."}
								pageCount={pageCount}
								marginPagesDisplayed={2}
								pageRangeDisplayed={3}
								onPageChange={handlePageClick}
								containerClassName={"pagination justify-content-center"}
								pageClassName={"page-item"}
								pageLinkClassName={"page-link"}
								previousClassName={"page-item"}
								previousLinkClassName={"page-link"}
								nextClassName={"page-item"}
								nextLinkClassName={"page-link"}
								breakClassName={"page-item"}
								breakLinkClassName={"page-link"}
								activeClassName={"active"}
							/>
						</div>
					)} */}

          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="form-control"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="tel"
                    placeholder="Contact Number"
                    className="form-control"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="col-md-12">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="Address" 
                                    className="form-control" 
                                    value={address}
                                    onChange={(e)=>setAddress(e.target.value)}
                                />
                            </div>
                        </div> */}
              {/* <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="City"
                                    className="form-control"
                                    value={city}
                                    onChange={(e)=>setCity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="State/Province" 
                                    className="form-control" 
                                    value={statedata}
                                    onChange={(e)=>setStateData(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="Postal Code" 
                                    className="form-control" 
                                    value={postalCode}
                                    onChange={(e)=>setPostalCode(e.target.value)}
                                />
                            </div>
                        </div> */}

              <div className="col-md-12">
                <div className="form-group">
                  <InputAutoComplete
                    // setPropData={setPropData}
                    // setCoordinates={setCoordinates}
                    setZip={setPostalCode}
                    setCity={setCity}
                    setState={setStateData}
                    setCountry={setCountry}
                    setAddress={setAddress}
                    setCompleteAddress={setCompleteAddress}
                    completeAddress={completeAddress}
                 
                    // setSendCord={setSendCord}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <select
                    id="category"
                    onChange={(e) => {
                      setCateType(e.target.value);
                    }}
                  >
                    <option selected="">Select Position</option>
                    {Cate?.map((item, index) => {
                      return (
                        <option value={item?.name} key={index}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <textarea
                    type="text"
                    rows="4"
                    cols="50"
                    placeholder="Enter your message"
                    className="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="attach_file">
                  <label for="cv">Attach Cv ( PDF)</label>
                  {/* <input type="file" id="cv" accept=".pdf" className="form-control" /> */}
                  <input
                    type="file"
                    id="cv"
                    accept="application/pdf"
                    // application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={handleImageUpload}
                    className="form-control"
                    multiple="false"
                  />
                  {mediaFile ? (
                    <FontAwesomeIcon icon={faFileLines} className="file-icon" />
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="button-group mt-3">
                  <button
                    className="btn"
                    type="submit"
                    data-dismiss="modal"
                    onClick={(e) => UploadResume(e)}
                    disabled={loading}
                  >
                    {loading ? "Loading.." : "APPLY JOB"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* <!-- Feature Jobs End Here --> */}
      <Footer />
    </>
  );
}

export default Careers;
