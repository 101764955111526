import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { arrow, basket, location2, logo2, mrLogo, separator } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { ramdomImage } from "../../constant/ConstantFunction";
import { GetAllCategories } from "../../network/Network";
import { CategoriesData } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Header(props) {
	const { setSelectCate } = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const Token = useSelector((state) => state.AuthReducer.token);
	const userData = useSelector((state) => state.AuthReducer.users);
	const isLogin = useSelector((state) => state.AuthReducer.isLogin);
	const CategoriesDataRedux = useSelector(
		(state) => state.AuthReducer.categoriesData,
	);
	const [currentPage, setCurrentPage] = useState(1);
	const CartReduxData = useSelector((state) => state.CartReducer.cartData);

	let currentUrl = window.location.href.split("/");

	// Menu Categories
	useEffect(() => {
		GetAllCategories(currentPage)
			.then((res) => {
				dispatch(CategoriesData(res?.data?.response?.data));
			})
			.catch((err) => {
				console.log(err);
			});
	}, [currentPage]);

	const LoginHandler = (e) => {
		e.preventDefault();
		toast.info("Please Login");
		navigate("/login");
	};

	return (
		<>
			{/* <!-- ======= Header ======= --> */}
			<header className="bg-header stack-header">
				<nav className="navbar navbar-expand-lg">
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarTogglerDemo02"
						aria-controls="navbarTogglerDemo02"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon">
							<FontAwesomeIcon icon={faBars} className="hamburg" />
						</span>
					</button>
					<div className="collapse navbar-collapse" id="navbarTogglerDemo02">
						<ul className="navbar-nav ml-auto mt-2 mt-lg-0">
							<li className="nav-item active">
								<Link
									to="/"
									className={`${
										currentUrl?.[3] === ""
											? "header_active nav-link"
											: "nav-link"
									}`}
								>
									HOME
								</Link>
							</li>
							<li className="nav-item active menu-dropdown">
								<Link
									to={`/menu`}
									state={{ data: " " }}
									className={`${
										currentUrl?.[3] === "menu"
											? "header_active nav-link drop-downShow"
											: "nav-link drop-downShow"
									}`}
								>
									MENU 
									{/* <i className="fa fa-caret-down" aria-hidden="true"></i> */}
								</Link>
								{/* <div className="mega_menu">
									<ul className="mega_menu_list">
										<div className="row">
											{CategoriesDataRedux?.map((item, index) => {
												return (
													<>
														<div className="col-md-2" key={index}>
															<li className="menu-item">
																<Link to={`/menu`} state={{ data: item?.id }}>
																	{item?.title}
																</Link>
															</li>
														</div>
													</>
												);
											})}
										</div>
									</ul>
								</div> */}
							</li>
							<li className="nav-item">
								<Link
									to="/find-restaurant"
									className={`${
										currentUrl?.[3] === "find-restaurant"
											? "header_active nav-link"
											: "nav-link"
									}`}
								>
									FIND RESTAURANT
								</Link>
							</li>
						</ul>
						<div className="mx-auto">
							<Link to="/" className="navbar-brand">
								<img src={mrLogo} alt="logo" />
							</Link>
						</div>

						<ul className="navbar-nav mr-auto mt-2 mt-lg-0">
							{/* <li className="nav-item">
								<Link
									to="/careers"
									className={`${
										currentUrl?.[3] === "careers"
											? "header_active nav-link"
											: "nav-link"
									}`}
								>
									CAREERS
								</Link>
							</li> */}
							<li className="nav-item">
								<Link
									to="/contact-us"
									className={`${
										currentUrl?.[3] === "contact-us"
											? "header_active nav-link"
											: "nav-link"
									}`}
								>
									CONTACT US
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/reviews"
									className={`${
										currentUrl?.[3] === "reviews"
											? "header_active nav-link"
											: "nav-link"
									}`}
								>
									REVIEWS
								</Link>
							</li>

							{/* <!-- <li className="nav-item">
                        <a className="nav-link" href="loyality.php">LOYALTY</a>
                    </li> -->
                    <!-- <li className="nav-item">
                        <a className="nav-link" href="gift-card.php">GIFT CARDS</a>
                    </li> --> */}

							{/* {isLogin ? (
                <li className="nav-item">
                  <Link to="/my-orders" 
                    className={`${
                      currentUrl?.[3] === "my-orders" ? "header_active nav-link" : "nav-link"
                    }`}
                  >
                    ORDER
                  </Link>
                </li>
              ) : (
                <li className="nav-item">
                  <a onClick={(e) => LoginHandler(e)} className="nav-link"
                    style={{cursor: "pointer"}}
                  >
                    ORDER
                  </a>
                </li>
              )} */}

							{/* <li className="nav-item">
                {isLogin ? (
                  <Link to="/profile" 
                  className={`${
                    currentUrl?.[3] === "profile" ? "header_active nav-link" : "nav-link"
                  }`}
                  >
                    <div className="signin">
                      <div className="header-profile">
                        {userData?.profile_picture == null ? (
                          <img
                            src={`${ramdomImage(
                              `${userData?.first_name}{""}${userData?.last_name}`
                            )}`}
                            className="image-fluid image-width"
                            style={{ borderRadius: "50%" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${userData?.profile_picture}`}
                            className="image-fluid image-width"
                            style={{ height: "100%" }}
                            alt=""
                          />
                        )}
                      </div>

                      <div className="txt ml-2">
                        <span>
                          {userData?.first_name} {userData?.last_name}
                        </span>
                        <p>Account</p>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link to="/login" className="nav-link">
                    <div className="signin">
                      <div className="icons">
                        <i className="fa fa-user-o" aria-hidden="true"></i>
                      </div>
                      <div className="txt">
                        <span>Sign In</span>
                        <p>Account</p>
                      </div>
                    </div>
                  </Link>
                )}
              </li> */}

							{/* <li className="nav-item">
                <Link to="/your-cart" 
                  className={`${
                    currentUrl?.[3] === "your-cart" ? "header_active nav-link" : "nav-link"
                  }`}
                >
                  <img src={basket} className="img-fluid" />
                  <span className="count">{CartReduxData?.length}</span>
                </Link>
              </li> */}
						</ul>
					</div>
				</nav>
			</header>
			{/* // <!-- End Header --> */}

			{/* // <!-- Order Online Modal Sec Start Here --> */}
			<div
				className="modal fade order-modal"
				id="order-online-modal"
				tabindex="-1"
				role="dialog"
				aria-labelledby="order-online-modalTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<div className="cancel_btn">
								<button data-dismiss="modal">X</button>
							</div>
							<div className="heading_wrapper">
								<h4 className="sub-title">DELICIOUS FOODS</h4>
								<h2 className="title">ORDER ONLINE</h2>
								<div className="seprator">
									<figure>
										<img src={separator} className="img-fluid" />
									</figure>
								</div>
							</div>
							<div className="button-group">
								<button
									type="button"
									className="btn"
									data-dismiss="modal"
									data-toggle="modal"
									data-target="#restaurent-location"
								>
									<img src={location2} className="img-fluid" /> Restaurant
									Location
								</button>
								<div className="spacer"></div>
								<div className="form-group">
									<select className="form-control">
										<option selected>Pick Up or Delivery</option>
										<option>Pick Up</option>
										<option>Delivery</option>
									</select>
								</div>
							</div>
							<form>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<input
												type="text"
												placeholder="Your Location"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input type="date" className="form-control" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input type="time" className="form-control" />
										</div>
									</div>
									<div className="col-md-12">
										<div className="buttons-group">
											<button className="btn">
												ORDER NOW
												<img src={arrow} className="img-fluid" />
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Order Online Modal Sec End Here --> */}

			{/* <!-- Restaurent Location Modal Start Here --> */}
			<div
				className="modal fade"
				id="restaurent-location"
				tabindex="-1"
				role="dialog"
				aria-labelledby="restaurent-locationTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<div className="cancel_btn">
								<button data-dismiss="modal">X</button>
							</div>
							<div className="heading_wrapper">
								<h2>CHOOSE STORE</h2>
							</div>
							<div className="resaturents_box">
								<div className="row">
									<div className="col-md-4">
										<div className="title">
											<h4>A TO B</h4>
										</div>
										<ul className="list">
											<li>
												<a href="#">Alpha</a>
											</li>
											<li>
												<a href="#">Beta</a>
											</li>
											<li>
												<a href="#" className="active">
													Canada
												</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
											<li>
												<a href="#">Lorem Ipsum</a>
											</li>
										</ul>
									</div>
									<div className="col-md-8">
										<div className="restaurent_details">
											<div className="name">
												<h5>Restaurant Name</h5>
												<p>Hill street, sydney Australia</p>
											</div>
											<div className="address">
												<h5>
													<i
														className="fa fa-map-marker"
														aria-hidden="true"
													></i>{" "}
													Sco 123 Main Road, Hill District
												</h5>
											</div>
											<div className="details">
												<p>
													<span className="proeprty">Pick up Hours :</span>
													<span className="value">
														9.00AM to 12PM (Mon-Sun)
													</span>
												</p>
												<p>
													<span className="proeprty">Delivery Hours :</span>
													<span className="value">
														9.00AM to 12PM (Mon-Sun)
													</span>
												</p>
												<p>
													<span className="proeprty">Cuisines :</span>
													<span className="value">
														{" "}
														Lunch, Breakfast, Dinner
													</span>
												</p>
												<p>
													<span className="proeprty">Featured :</span>
													<span className="value"> Treading</span>
												</p>
												<p>
													<span className="proeprty">Discount :</span>
													<span className="value"> 20% of on all orders</span>
												</p>
												<div className="ratings">
													<span className="proeprty">Reviews :</span>
													<ul className="ratings">
														<li>
															<i className="fa fa-star" aria-hidden="true"></i>
														</li>
														<li>
															<i className="fa fa-star" aria-hidden="true"></i>
														</li>
														<li>
															<i className="fa fa-star" aria-hidden="true"></i>
														</li>
														<li>
															<i className="fa fa-star" aria-hidden="true"></i>
														</li>
														<li>
															<i className="fa fa-star" aria-hidden="true"></i>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="button-group">
									{/* <!-- <a href="#" className="btn">SELECT STORE <img src="img/arrow.png" className="img-fluid"></a> --> */}
									<button
										className="btn"
										type="button"
										data-toggle="modal"
										data-dismiss="modal"
										data-target="#order-online-modal"
									>
										SELECT STORE <img src={arrow} className="img-fluid" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Restaurent Location Modal End Here --> */}
		</>
	);
}

export default Header;
