import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import "../../assets/css/sign-up.css";
import { rightArrow } from '../../constant';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { login } from '../../redux/actions/AuthActions'
import { useDispatch } from 'react-redux'

function Login() {
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const [ password, setPassword ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [checked, setChecked] = useState(false);

    const handleRememeberMe = (event) => {
      setChecked(!checked);
    };

    const SignInHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!email || !password) {
          toast.error("Please Enter All Fields");
          setLoading(false);
          return;
        }
        if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              email
            )
          ) {
            toast.error("Invalid Email");
            setLoading(false);
            return;
          }
          else {
            localStorage.setItem("rememberMe", checked);
            localStorage.setItem("email", checked ? email : "");
            localStorage.setItem("password", checked ? password : "");

            let data = {
                email: email,
                password: password,
              };
              console.log(data);
              setTimeout(async () => {
                setLoading(false);
                let x = await dispatch(login(data));
                console.log("response login", x);
                if (x) {
                  console.log(x);
                  window.location.href = "/profile";
                  // Navigate("/profile");
                }
              }, 600);
          }
     
      };

      console.log(checked);

      useEffect(() => {
        const rememberMe = localStorage.getItem("rememberMe") === "true";
        const email = rememberMe ? localStorage.getItem("email") : "";
        const password = rememberMe ? localStorage.getItem("password") : "";
        setEmail(email);
        setPassword(password);
        setChecked(rememberMe);
      }, []);

  return (
   <>
    <Header />
    {/* <!-- Universal Banner Sec Start Here --> */}
<section className="universal_banner">
    <div className="container">
        <div className="page_details">
            <h2 className="title">SIGN IN</h2>
            <ul className="breadcrumbs">
                <li><Link to="/">HOME PAGE</Link></li>
                <li className="active">SIGN IN</li>
            </ul>
        </div>
    </div>
</section>
{/* <!-- Universal Banner Sec End Here --> */}

{/* <!-- login starts here --> */}
<section className="checkout">
    <div className="container">
        <form >
            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 kip">
                    <div className="first-top-bar">
                        <h3>Sign In</h3>
                        <p>Does'nt Have an account? <Link to="/signup">Sign Up</Link> </p>
                    </div>
                    <div className="add-new-address">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="Email">Email</label>
                                    <input 
                                        type="email" 
                                        id="Email" 
                                        className="form-control"
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="Password">Password</label>
                                    <input 
                                        type="password" 
                                        id="Password" 
                                        className="form-control"
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group text-right">
                                    <Link to="/forgot-password">Forgets Password?</Link>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="form-check">
                                            <input 
                                                type="checkbox" 
                                                className="form-check-input" 
                                                id="exampleCheck1" 
                                                value={checked}
                                                onChange={(e) => handleRememeberMe(e)}
                                            />
                                            <label className="form-check-label" for="exampleCheck1">Remember Me</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <div className="add-new-method">

                                            <button 
                                                type="button" 
                                                className="btn"
                                                onClick={(e)=>SignInHandler(e)}
                                                disabled={loading}
                                            >
                                                 {loading ? "Loading.." : "SIGN IN"}  
                                              &nbsp; <img src={rightArrow} alt="" /></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="payment-details">

                        <div className="saved-payment-method">

                            {/* <!-- Saved payments Method --> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-3"></div>
            </div>
        </form>
    </div>
</section>
{/* <!-- login ends here --> */}

    <Footer />
   </>
  )
}

export default Login