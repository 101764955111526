import { Rating } from "react-simple-star-rating";
import {
    AUTH,
    SIGNUP,
    // end 

    LOGIN,
    CATEGORIES,
    FAQ,
    CONTACTUS,
    COMPANY_INFORMATION,
    CUSTOMER,
    UPDATE_PROFILE,
    PRODUCTS,
    LATEST,
    STATUS,
    WISHLIST,
    REMOVE,
    ORDER,
    LIST,
    QUESTION_STORE,
    STORE_USER_ADDRESS,
    MENU_DETAIL,
    // ---
    HOTDEALS,
    RELATED_PRODUCTS,
    TRADING,
    REVIEWS,

    NEWSLETTER,
    ADD,
    PLACE,
    TRACK,
    REMOVE_ALL,
    CUSTOMER_ID,
    SOCIAL_MEDIA,
    LINKS,
 
    COUPON,
    APPLY,
    CODE,

    PRODUCT_ID,
    SEARCH,
    NAME,
    SUBMIT,
    BRANDS,
    PAGE,
    PRICE,
    START,
    END,
    BANNERS,
    BANNER_TYPE,

    ADS,
    BLOGS,
    FORGOT_PASSWORD,
    COUNTRY,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    TOP_PRODUCTS,
    GET_PAGE,
    TYPE,
    CONTACT,
    STORE,
    SPONSORS,
    EVENT,
    EVENTS,
    PACKAGE,
    CONDITION,
    CAR,
    MAKE,
    OPTIONS,
    COLOR,
    CATEGORY,
    INSTANTOFFER,
    ALL_LISTING,
    CAR_CATEGORY_ID,
    MAKE_ID,
    MODEL_ID,
    SHOWCASE_LISTING,
    POST,
    CONFIG,
    ADD_LISTING,
    BUSINESS_SETTINGS,
    PAYMENT_METHOD,
    SHOW_CARD,
    REMOVE_CARD,
    UPDATE_DEFAULT_CARD,
    CSH_VIDEO,
    VIDEOS,
    CHAT,
    CREATE,
    GET,
    MESSAGE,
    SEND,
    GETINSTANTOFFER,
    VIEW_CAR,
    UPDATE_STATUS,
    GET_EVENT,
    FAVOURITE,
    DETAILS,
    LOAN,
    CALCULATOR,
    STORE_EVENT,
    EDIT,
    DELETE,
    USER,
    INFO,
    CONTACT_STORE,
    RESTAURANT,
    GET_RESTAURANT,
    RATING,
    GET_RATING,
    SEARCH_RESTAURANT,
    PROVINCE,
    CAREER,
    GET_CAREER,
    GET_CATEGORY,
    PAGES,
    GET_PAGES,
    UPDATE,
    CAREER_DETAIL,
    GET_FAQS,
    GET_BLOG,
    BLOG_DETAIL,
    CAREER_APPLICATION,
    STORE_APPLICATION,
    MENU,
    GET_MENU,
    CATEGORY_ID,
    MY_ORDERS,
    ADDRESS,
    STORE_ADDRESS,
    GET_ADDRESS,
    REMOVE_ADDRESS,
    UPDATE_ADDRESS,
    GET_RATED_MENU,
    SETTING,
    GET_SETTINGS,
    PLACE_ORDER,
    NEWS_LETTER_STORE,
    ORDER_CANCELLATION,
    STORE_RATING,
    GET_MEDIA_FILES,
    // FROGOT_PASSWORD,
  } from "../network/Endpoint";
  
  import {
    doGet,
    doPost,
    doPatch,
    doDelete,
    doPostProfilePictureUpload,
  } from "./Config";
  
  export const postSignUp = (data) => {
    return doPost(`${AUTH}${STORE}`, data);
  };
  
  export const postSignIn = (data) => {
    return doPost(`${AUTH}${LOGIN}`, { ...data });
  };

  export const forgotPasswordApi = (data) => {
    return doPost(`${AUTH}${FORGOT_PASSWORD}`, data);
  };

  export const ResetPasswordApi = (data) => {
    return doPost(`${AUTH}${RESET_PASSWORD}`, data);
  };


  export const PostChangePassword = (data, token) => {
    return doPost(`${USER}${CHANGE_PASSWORD}`, data, token);
  };

  export const PostContactUs = (data) => {
    return doPost(`${CONTACT_STORE}`, data);
  };

  export const GetAllRestaurants = () => {
    return doGet(`${RESTAURANT}${GET_RESTAURANT}`);
  };

  export const GetAllRestaurantsSearch = (data) => {
    return doGet(`${RESTAURANT}${GET_RESTAURANT}${"?search"}=${data}`);
  };

  export const GetSearchRestaurant = (data) => {
    return doGet(`${RESTAURANT}${SEARCH_RESTAURANT}${PROVINCE}=${data?.province}${SEARCH}=${data?.search}`);
  };

  export const GetAllReviews = (currentPage) => {
    return doGet(`${RATING}${GET_RATING}${"?page"}=${currentPage}`);
  };

  export const GetAllCareers = (currentPage,data) => {
    return doGet(`${CAREER}${GET_CAREER}${"?search"}=${data}${"&page"}=${currentPage}`);
  };

  export const GetCareerDetails = (id) => {
    return doGet(`${CAREER}${CAREER_DETAIL}${"?"}${"id"}=${id}`);
  };



  export const GetAllCategories = (currentPage) => {
    return doGet(`${CATEGORY}${GET_CATEGORY}${PAGE}=${currentPage}`);
  };

  export const GetPagesApi = () => {
    return doGet(`${PAGES}${GET_PAGES}`);
  };

  export const UpdateProfile = (data, token) => {
    return doPostProfilePictureUpload(
      `${USER}${UPDATE}`,
      data,
      token
    );
  };

  export const GetFaqs = () => {
    return doGet(`${FAQ}${GET_FAQS}`);
  };

  export const GetBlogs = (currentPage,search) => {
    return doGet(`${BLOGS}${GET_BLOG}${"?search"}=${search ? search : ""}${'&page'}=${currentPage}`);
  };

  export const GetLatestBlogs = () => {
    return doGet(`${BLOGS}${GET_BLOG}`);
  };

  export const GetBlogDetails = (id) => {
    return doGet(`${BLOGS}${BLOG_DETAIL}${"?id"}=${id}`);
  };

  export const PostResume = (data) => {
    return doPost(`${CAREER_APPLICATION}${STORE_APPLICATION}`, data);
  };

  export const GetAllMenu = (id,currentPage) => {
    return doGet(`${MENU}${GET_MENU}${CATEGORY_ID}=${id}${"&page"}=${currentPage}`);
  };

  export const GetMyOrders = (currentPage,token) => {
    return doGet(`${ORDER}${MY_ORDERS}${PAGE}=${currentPage}`,token);
  };

  export const PlaceOrder = (data, token) => {
    return doPost(`${ORDER}${PLACE_ORDER}`, data, token);
  };

  export const PostAddress = (data, token) => {
    return doPost(`${ADDRESS}${STORE_ADDRESS}`, data, token);
  };

  export const GetAllAddress = (token) => {
    return doGet(`${ADDRESS}${GET_ADDRESS}`, token);
  };

  export const DeleteAddress = (data, token) => {
    return doPost(`${ADDRESS}${REMOVE_ADDRESS}`, data, token);
  };

  export const PostDefaultAddress = (data, token) => {
    return doPost(`${ADDRESS}${UPDATE_ADDRESS}`, data, token);
  };

  export const GetAllRecomendation = () => {
    return doGet(`${MENU}${GET_RATED_MENU}`, );
  };

  export const GetSettingPages = () => {
    return doGet(`${SETTING}${GET_SETTINGS}`, );
  };

  export const PostNewsLetter = (data) => {
    return doPost(`${NEWS_LETTER_STORE}`, data);
  };

  export const OrderCancellation = (data,token) => {
    return doGet(`${ORDER}${ORDER_CANCELLATION}${"?"}${"order_id"}=${data?.id}`,token);
  };

  export const PostRating = (data) => {
    return doPost(`${RATING}${STORE_RATING}`, data);
  };

  export const GetHomeSlider = () => {
    return doGet(`${MENU}${GET_MEDIA_FILES}`, );
  };

  export const GetDiscount = (data,token) => {
    return doGet(`${"/coupon"}${"/get-discount"}${"?code"}=${data?.code}${"&price"}=${data?.price}`, token );
  };

  export const GetMenuDetail = (id) => {
    return doGet(`${MENU}${MENU_DETAIL}${"?id"}=${id}`, );
  };
  


  // END STACK 






  export const GetAllSponsors = () => {
    return doGet(`${SPONSORS}`);
  };

  export const GetAllEvents = (selectState,currentPage) => {
    return doGet(`${EVENT}${selectState ? "/" : ""}${selectState}${PAGE}=${currentPage}`);
  };
  
  export const PostEventReservation = (data,token) => {
    return doPost(`${CUSTOMER}${EVENTS}${STORE}`, data, token);
  };

 

  export const CarListingPackages = () => {
    return doGet(`${PACKAGE}`);
  };

  export const GetCarConditions = () => {
    return doGet(`${CAR}${CONDITION}`);
  };


  
  export const GetCarListingParam = (Data) => {
    return doGet(`${CAR}${ALL_LISTING}${CAR_CATEGORY_ID}=${Data?.cate}${MAKE_ID}=${Data?.make}${MODEL_ID}=${Data?.model}`);
  };



  export const PostCarListing = (data,token) => {
    return doPostProfilePictureUpload(`${CUSTOMER}${POST}${STORE}`, data, token);
  };

  export const EditPostCarListing = (data,token) => {
    return doPostProfilePictureUpload(`${CUSTOMER}${POST}${EDIT}`, data, token);
  };

  export const GetMyCarListing = (token,currentPage) => {
    return doGet(`${CUSTOMER}${POST}${PAGE}=${currentPage}`,token);
  };
// ---test 
// ${`?`}${`page`}=${currentPage}




  export const GetAddListPrice = () => {
    return doGet(`${CONFIG}${ADD_LISTING}`);
  };



  export const PostSaveCard = (data,token) => {
    return doPost(`${CUSTOMER}${PAYMENT_METHOD}${STORE}`, data, token);
  };

  export const GetAllCard = (token) => {
    return doGet(`${CUSTOMER}${PAYMENT_METHOD}${SHOW_CARD}`, token);
  };
  
  export const GetImageUrls = () => {
    return doGet(`${CONFIG}` );
  };

  export const DeletePaymentMethodsApi = (data, token) => {
    return doPost(`${CUSTOMER}${PAYMENT_METHOD}${REMOVE_CARD}`, data, token);
  };

  export const PostPaymentDefaultCard = (data, token) => {
    return doPost(`${CUSTOMER}${PAYMENT_METHOD}${UPDATE_DEFAULT_CARD}`, data, token);
  };
  
  export const GetFilterCar = (currentPage = null,data) => {
    let link = `${CAR}${ALL_LISTING}`;
    if(data){
      link += '?';
      link += (data.color_code)? `${"color_code"}=${data?.color_code}&` : '';  
      link += (data.mileage)? `${"mileage"}=${data?.mileage}&` : '';  
      link += (data.car_category_id)? `${"car_category_id"}=${data?.car_category_id}&` : '';  
      link += (data.make_id)? `${"make_id"}=${data?.make_id}&` : '';  
      link += (data.model_id)? `${"model_id"}=${data?.model_id}&` : '';  
      link += (data.condition_id)? `${"condition_id"}=${data?.condition_id}&` : '';  
      link += (data.drive_type_id)? `${"drive_type_id"}=${data?.drive_type_id}&` : '';  
      link += (data.transmission_id)? `${"transmission_id"}=${data?.transmission_id}&` : '';  
      link += (data.fuel_type_id)? `${"fuel_type_id"}=${data?.fuel_type_id}&` : '';  
      link += (data.min_year)? `${"min_year"}=${data?.min_year}&` : '';  
      link += (data.max_year)? `${"max_year"}=${data?.max_year}&` : '';  
      link += (data.min_price)? `${"min_price"}=${data?.min_price}&` : '';  
      link += (data.max_price)? `${"max_price"}=${data?.max_price}&` : '';
      link += (currentPage)? `${"page"}=${currentPage}` : ''
    }
    console.log(link);
    return doGet(link,data?.car_category_id);
  };

  export const GetCarListingInstantOffer = (currentPage = null,data) => {
    let link = `${CAR}${INSTANTOFFER}`;
    if(data){
      link += '?';
      link += (data.color_code)? `${"color_code"}=${data?.color_code}&` : '';  
      link += (data.mileage)? `${"mileage"}=${data?.mileage}&` : '';  
      link += (data.car_category_id)? `${"car_category_id"}=${data?.car_category_id}&` : '';  
      link += (data.make_id)? `${"make_id"}=${data?.make_id}&` : '';  
      link += (data.model_id)? `${"model_id"}=${data?.model_id}&` : '';  
      link += (data.condition_id)? `${"condition_id"}=${data?.condition_id}&` : '';  
      link += (data.drive_type_id)? `${"drive_type_id"}=${data?.drive_type_id}&` : '';  
      link += (data.transmission_id)? `${"transmission_id"}=${data?.transmission_id}&` : '';  
      link += (data.fuel_type_id)? `${"fuel_type_id"}=${data?.fuel_type_id}&` : '';  
      link += (data.min_year)? `${"min_year"}=${data?.min_year}&` : '';  
      link += (data.max_year)? `${"max_year"}=${data?.max_year}&` : '';  
      link += (data.min_price)? `${"min_price"}=${data?.min_price}&` : '';  
      link += (data.max_price)? `${"max_price"}=${data?.max_price}&` : '';
      link += (currentPage)? `${"page"}=${currentPage}` : ''
    }
    console.log(link);
    return doGet(link,data?.car_category_id);
    // return doGet(`${CAR}${INSTANTOFFER}`);
  };

  export const GetCSHVideo = () => {
    return doGet(`${CONFIG}${VIDEOS}` );
  };




  export const PostCreateChat = (data, token) => {
    return doPost(`${CUSTOMER}${CHAT}${CREATE}`, data, token);
  };
  
  export const GetChatUserList = (user,token) => {
    return doGet(`${CUSTOMER}${CHAT}${GET}${LIST}${`?`}${`user`}=${user}`, token);
  };

  export const PostSelectedUserMessage = (data, token) => {
    return doPost(`${CUSTOMER}${CHAT}${GET}${MESSAGE}`,data, token);
  };

  export const SendMessage = (data, token) => {
    return doPost(`${CUSTOMER}${CHAT}${SEND}${MESSAGE}`, data, token);
  };

  export const SocialLinks = () => {
    return doGet(`${SOCIAL_MEDIA}`);
  };
  
  export const GetMyOffers = (currentPage,token) => {
    return doGet(`${CUSTOMER}${POST}${GETINSTANTOFFER}${`?`}${`page`}=${currentPage}`, token);
  };

  export const PostCarViews = (data, token) => {
    return doPost(`${VIEW_CAR}${STORE}`, data, token);
  };

  export const GetCarViews = () => {
    return doGet(`${VIEW_CAR}`, );
  };

  export const DeactivatePost = (id, token) => {
    return doGet(`${CUSTOMER}${POST}${UPDATE_STATUS}/${id}`, token);
  };

  export const GetReservedEvent = (currentPage, token) => {
    return doGet(`${CUSTOMER}${GET_EVENT}${`?`}${`page`}=${currentPage}`, token);
  };

  export const PostFavourite = (data, token) => {
    return doPost(`${CUSTOMER}${FAVOURITE}${STORE}`, data, token);
  };

  export const PostFavouriteDelete = (id, token) => {
    return doDelete(`${CUSTOMER}${FAVOURITE}${REMOVE}/${id}`, token);
  };

  export const GetAllFavourite = (currentPage, token) => {
    return doGet(`${CUSTOMER}${FAVOURITE}${`?`}${`page`}=${currentPage}`, token);
  };

  export const GetAllProducts = (currentPage, data) => {
    return doGet(
      `${PRODUCTS}${LATEST}${STATUS}=${data?.status}${`&`}${`start`}=${data?.start}${`&`}${`end`}=${data?.end}${`&`}${`page`}=${currentPage}`
    );
  };

  export const MerchanProductDeatils = (id) => {
    return doGet(`${PRODUCTS}${DETAILS}/${id}`,);
  };

  export const ApplyCoupons = (data) => {
    return doGet(`${COUPON}${APPLY}${CODE}=${data}`);
  };


 

  export const LoanCalculator = (data) => {
    return doPost(`${LOAN}${CALCULATOR}`, data);
  };

  export const PostEvents = (data,token) => {
    return doPost(`${CUSTOMER}${EVENTS}${STORE_EVENT}`, data, token);
  };

  export const AddWishList = (data, token) => {
    return doPost(`${CUSTOMER}${WISHLIST}${ADD}`, data, token);
  };

  export const GetWishList = (currentPage, token) => {
    return doGet(`${CUSTOMER}${WISHLIST}${PAGE}=${currentPage}`, token);
  };

  export const DeleteWishList = (id, token) => {
    return doDelete(`${CUSTOMER}${WISHLIST}${REMOVE}${PRODUCT_ID}=${id}`, token);
  };

  export const EventDelete = (id, token) => {
    return doGet(`${CUSTOMER}${EVENTS}${DELETE}/${id}`, token);
  };

  // end CAR




  export const CompanyInfo = () => {
    return doGet(`${COMPANY_INFORMATION}`);
  };

  // export const UpdateProfile = (data, token) => {
  //   return doPostProfilePictureUpload(
  //     `${CUSTOMER}${UPDATE_PROFILE}`,
  //     data,
  //     token
  //   );
  // };





  // export const AddWishList = (data, token) => {
  //   return doPost(`${CUSTOMER}${WISHLIST}${ADD}`, data, token);
  // };



  export const ClearWishList = (id, token) => {
    return doDelete(
      `${CUSTOMER}${WISHLIST}${REMOVE_ALL}${CUSTOMER_ID}=${id}`,
      token
    );
  };




  export const forgotPasswordOtp = (data) => {
    return doPost(`${AUTH}${RESET_PASSWORD}`, data);
  };
  // ------ end

  