
export const ApiUrl =
"https://stack.developer-ourbase-camp.com/api" ||
"http://localhost:4000";



// export const ApiUrl =
// "http://192.168.3.141/api/v1" ||
// "http://localhost:4000";



