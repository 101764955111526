import React from "react";
import Slider from "react-slick";
import {
	image0,
	image2,
	image3,
	image4,
	image5,
	image6,
	image7,
	image8,
	image9,
	image10,
	image11,
	image12,
	image13,
	image14,
	image15,
	image16,
} from "../../constant";
import { Carousel } from "@trendyol-js/react-carousel";

function FoodSlider(props) {
	const { sliderData } = props;
	const foodData = [
		{
			id: 1,
			img: image0,
			sub: "Double Decker BLT",
			// sub: "Hearty Breakfast",
		},
		{
			id: 2,
			img: image2,
			sub: "Mediterranean Wrap",
			// sub: "Warm Lunch",
		},
		{
			id: 3,
			img: image3,
			sub: "Nutella Crepes",
			// sub: "Magic Waffles",
		},
		{
			id: 4,
			img: image4,
			sub: "Red Velvet French Toast",
			// sub: "Magic of Pancakes",
		},
		// {
		// 	id: 5,
		// 	img: image5,
		// 	sub: "Start Off with our",
		// 	sub: "Hearty Breakfast",
		// },
		{
			id: 6,
			img: image6,
			sub: "CYO omelette",
			// sub: "Warm Lunch",
		},
		// {
		// 	id: 7,
		// 	img: image7,
		// 	sub: "Our Popular",
		// 	sub: "Magic Waffles",
		// },
		{
			id: 8,
			img: image8,
			sub: "South West Chicken Sandwich",
			// sub: "Magic of Pancakes",
		},
		// {
		// 	id: 9,
		// 	img: image9,
		// 	sub: "Treat Yourself to a",
		// 	sub: "Warm Lunch",
		// },
		{
			id: 10,
			img: image10,
			sub: "Cheesecake pancakes",
			// sub: "Magic Waffles",
		},
		// {
		// 	id: 11,
		// 	img: image11,
		// 	sub: "Start Yor Day with",
		// 	sub: "Magic of Pancakes",
		// },
		{
			id: 12,
			img: image12,
			sub: "S'more Pancakes",
			// sub: "Magic of Pancakes",
		},
		{
			id: 13,
			img: image13,
			sub: "Meat lover skillet",
			// sub: "Magic of Pancakes",
		},
		{
			id: 14,
			img: image14,
			sub: "Strawberry Banana Pancakes",
			// sub: "Magic of Pancakes",
		},
		{
			id: 15,
			img: image15,
			sub: "Crispy Chicken Wrap",
			// sub: "Magic of Pancakes",
		},
		// {
		// 	id: 16,
		// 	img: image16,
		// 	title: "Start Yor Day with",
		// 	sub: "Magic of Pancakes",
		// },
	];
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 2,
		autoplay: true,
		autoplaySpeed: 2000,
		// autoplaySpeed: 0,
		// arrows: false,
		speed: 5000,
		// easing: "linear",
	};
	return (
		<section id="diet-section">
			<div class="container">
				<div class="row">
					<Carousel
						show={4}
						slide={3}
						swiping={true}
						autoSwipe={true}
						infinite={true}
						transition={0.5}
					>
						{foodData?.map((item, index) => {
							return (
								<div class="col-md-3" key={index}>
									<div class="container-block">
										<div class="food_img_box">
											<figure>
												<img src={item?.img} />
											</figure>
										</div>
										<div class="inner-block">
											<div class="slider-top-right"></div>
										</div>
										<div class="block-content">
											<div class="image-text">
												<h4 class="font-weight-bold">{item?.sub}</h4>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</Carousel>
				</div>
			</div>
		</section>
	);
}

export default FoodSlider;

// {/* <Slider {...settings}>
// 				{foodData?.map((item, index) => {
// 					return (
// 						<>
// 							<div className="col-md-3" key={index}>
// 								<div className="container-block">
// 									<div className="food_img_box">
// 										<figure>
// 											<img src={item?.img} />
// 										</figure>
// 									</div>
// 									<div className="inner-block">
// 										<div className="slider-top-right"></div>
// 									</div>
// 									<div className="block-content">
// 										<div className="image-text">
// 											{/* <span>{item?.title}</span> */}
// 											<h5 className="font-weight-bold">{item?.sub}</h5>
// 										</div>
// 									</div>
// 								</div>
// 							</div>

// 					);
// 				})}
// 			</Slider>  */}
