import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import "../../assets/css/sign-up.css";
import { rightArrow } from '../../constant';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../redux/actions/AuthActions';
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

function ForgotPassword() {
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const [ email, setEmail ] = useState("")
    const [ loading, setLoading ] = useState("")
    const [checked, setChecked] = useState("");

    const ForgetPasswordHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if(!email){
          toast.error("please enter Your email");
          setLoading(false);
        }
        if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
          )
        ) {
          toast.error("please enter correct email");
          setLoading(false);
          return;
        } else {
          let data = { email: email };
          console.log(data);
          setTimeout(async () => {
            setLoading(false);
            let send = await dispatch(forgotPassword(data));
            // Navigate("/forgot-password-Otp", { state: email });
            // Navigate("/newpassword");
            // window.location.href = "/newpassword";
          }, 600);
        }
      };

  return (
   <>
    <Header />
    {/* <!-- Universal Banner Sec Start Here --> */}
<section className="universal_banner">
    <div className="container">
        <div className="page_details">
            <h2 className="title">FORGETS PASSWORD</h2>
            <ul className="breadcrumbs">
                <li><Link to="/">HOME PAGE</Link></li>
                <li className="active">FORGETS PASSWORD</li>
            </ul>
        </div>
    </div>
</section>
{/* <!-- Universal Banner Sec End Here --> */}
{/* <!-- login starts here --> */}
<section className="checkout">
    <div className="container">
        <form >
            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 kip">
                    <div className="first-top-bar">
                        <h3>Forgets Password</h3>
                    </div>
                    <div className="add-new-address">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="Email">Email</label>
                                    <input 
                                        type="email" 
                                        id="Email" className="form-control" 
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <p>An email has been sent to reset the password <br /> <small>Haven't Recieved the code? try again</small></p>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <div className="add-new-method">

                                            <button 
                                                type="button" 
                                                className="btn"
                                                disabled={loading}
                                                onClick={(e)=>ForgetPasswordHandler(e)}
                                            >
                                                {loading? "Loading.." : "SEND EMAIL"} 
                                                <img src={rightArrow} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-lg-3"></div>
            </div>
        </form>
    </div>
</section>
{/* <!-- login ends here --> */}
    <Footer />
   </>
  )
}

export default ForgotPassword