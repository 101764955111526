import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Login from "../pages/Auth/Login";
import NewPassword from "../pages/Auth/NewPassword";
import Signup from "../pages/Auth/Signup";
import Blog from "../pages/Blog/Blog";
import BlogDetails from "../pages/Blog/BlogDetails";
import Careers from "../pages/Careers/Careers";
import JobDetails from "../pages/Careers/JobDetails";
import Cart from "../pages/Cart/Cart";
import Checkout from "../pages/Cart/Checkout";
import ThankYou from "../pages/Cart/ThankYou";
import Contactus from "../pages/Contact/Contactus";
import Faqs from "../pages/Faqs/Faqs";
import Home from "../pages/Home/Home";
import Menu from "../pages/Menu/Menu";
import Menu2 from "../pages/Menu/Menu2";
import MenuDetail from "../pages/Menu/MenuDetail";
import NewsLetter from "../pages/NewsLetter/NewsLetter";
import CancelPolicy from "../pages/PolicyPages/CancelPolicy";
import LoyaltyPointPolicy from "../pages/PolicyPages/LoyaltyPointPolicy";
import PrivacyPolicy from "../pages/PolicyPages/PrivacyPolicy";
import TermCondition from "../pages/PolicyPages/TermCondition";
import Address from "../pages/Profile/Address";
import LoyaltyPoints from "../pages/Profile/LoyaltyPoints";
import MyOrders from "../pages/Profile/MyOrders";
import Profile from "../pages/Profile/Profile";
import Wallet from "../pages/Profile/Wallet";
import FindRestaurant from "../pages/Restaurant/FindRestaurant";
import RestaurantMenu from "../pages/Restaurant/RestaurantMenu";
import FeedBackForm from "../pages/Reviews/FeedBackForm";
import Reviews from "../pages/Reviews/Reviews";
import AboutLoyalty from "../pages/Story/AboutLoyalty";
import GiftCard from "../pages/Story/GiftCard";
import OurStory from "../pages/Story/OurStory";
import ProtectedRoutes from "./ProtectedRoutes";

function PublicRoutes(props) {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contact-us" element={<Contactus />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="review-form" element={<FeedBackForm />} />
          {/* <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} /> */}
          <Route path="forgot-password" element={<ForgotPassword />} />
         

          <Route path="careers" element={<Careers />} />
          <Route path="job-details/:Id" element={<JobDetails />} />
          <Route path="find-restaurant" element={<FindRestaurant />} />
          <Route path="restaurant-menu/:Id" element={<RestaurantMenu />} /> 
          {/* <Route path="your-cart" element={<Cart />} /> 
          <Route path="checkout" element={<Checkout />} />  */}
          <Route path="menu" element={<Menu2 />} /> 
          {/* <Route path="menu" element={<Menu />} />  */}
          <Route path="menu-detail/:id" element={<MenuDetail />} /> 

          <Route path="blog" element={<Blog />} /> 
          <Route path="blog-details/:Id" element={<BlogDetails />} /> 
          <Route path="term-condition" element={<TermCondition />} /> 
          <Route path="privacy-policy" element={<PrivacyPolicy />} /> 
          {/* <Route path="cancel-policy" element={<CancelPolicy />} />  */}
          <Route path="faqs" element={<Faqs />} /> 
          <Route path="/news-letter" element={<NewsLetter />} /> 
          <Route path="/our-story" element={<OurStory />} /> 

          <Route path="new-password" element={<NewPassword />} />
          {/* <Route path="loyalty-point-policy" element={<LoyaltyPointPolicy />} />
          <Route path="/about-loyalty" element={<AboutLoyalty />} /> 
          <Route path="/gift-card" element={<GiftCard />} />  */}
          
          {/* <Route path="/" element={<ProtectedRoutes />}>
            <Route path="profile" element={<Profile />} /> 
            <Route path="my-orders" element={<MyOrders />} /> 
            <Route path="my-loyalty-points" element={<LoyaltyPoints />} /> 
            <Route path="my-wallet" element={<Wallet />} /> 
            <Route path="address" element={<Address />} /> 
          </Route> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoutes;
