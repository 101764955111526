import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/newsletter.css";
import { newsletter } from "../../constant";
import { Link } from "react-router-dom";
import { PostNewsLetter } from "../../network/Network";
import { toast } from "react-toastify";

function NewsLetter() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [zip_code, setZip_code] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleRememeberMe = (event) => {
      setChecked(!checked);
    };

  const NewsLetterHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!firstName || !lastName || !email || !zip_code) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (!checked) {
      toast.error("Please Accept Our Term and Conditions");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
   
    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      zip_code: zip_code,
      is_agree: "Yes" ,
    };
    console.log(data);
    PostNewsLetter(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setFirstName("");
        setLastName("");
        setEmail("");
        setZip_code("");
        setChecked(!checked);
      })
      .catch((err) => {
        console.log(err);
        if(err?.response?.data?.errors?.email){
          toast.error(err?.response?.data?.errors?.email[0])
        }
        toast.error(err?.response?.data?.errors)
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">Newsletter</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">NEWSLETTER</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {/* <!-- Newsletter Sec Start Here --> */}
      <section className="newsletter_sec">
        <div className="container">
          <div className="newsletter_box">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="img_box">
                  <figure>
                    <img src={newsletter} className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="contact_details">
                  <div className="heading_wrapper">
                    <h3>SUBSCRIBE TO GET THE LATEST UPDATES</h3>
                    <p>
                      Your email address will not be published.required fields
                      are marked *
                    </p>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e)=>setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e)=>setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Postal Code"
                            value={zip_code}
                            onChange={(e)=>setZip_code(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group privacyDv">
                          <input
                            type="checkbox"
                            id="check"
                            value={checked} 
                            onChange={(e) => handleRememeberMe(e)}
                          />
                          <label for="check">
                            <span>You accept our</span> Terms and Conditions and
                            Privacy Policy
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <button 
                            className="btn"
                            disabled={loading}
                            onClick={(e) => NewsLetterHandler(e)}
                          >
                           {loading ? "Loading.." : "Subscribe"} 
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Newsletter Sec End Here --> */}
      <Footer />
    </>
  );
}

export default NewsLetter;
