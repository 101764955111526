import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/faq.css";
import { GetFaqs } from "../../network/Network";
import SpinLoader from "../../components/Loader/SpinLoader";
import { Link } from "react-router-dom";

function Faqs() {
  const [faqsData, setFaqsData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true)
    GetFaqs()
      .then((res) => {
        console.log(res);
        setFaqsData(res?.data?.response?.data?.data);
        setSpinLoad(false)
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false)
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">FAQ'S</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">FAQ'S</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="faq-wrapper">
                <div className="faq-heading-wrapper">
                  <h2>The Most Frequently Asked Questions</h2>
                </div>
                {
                    spinLoad ? (
                        <SpinLoader/>
                    ) : (
                        <div className="accordion mt-5" id="accordionExample">
                        {faqsData?.map((item, index) => {
                          return (
                            <div className="accordion-item" key={index}>
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={`#${item?.question}${item?.id}`}
                                  aria-expanded="true"
                                  aria-controls={`${item?.question}${item?.id}`}
                                >
                                  {item?.question}
                                </button>
                              </h2>
                              <div
                                id={`${item?.question}${item?.id}`}
                                className="accordion-collapse collapse show"
                                aria-labelledby={item?.id}
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">{item?.answer}</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )
                }
             
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Faqs;
