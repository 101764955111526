import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import "../../assets/css/thank-you.css";
import { arrow, details1, details2 } from '../../constant';
import { Link } from 'react-router-dom';

function ThankYou() {
    const orderData = [
        {
            id:1,
            img: details1,
        },
        {
            id:2,
            img: details2,
        },
        {
            id:3,
            img: details1,
        },
        {
            id:4,
            img: details2,
        },
        {
            id:5,
            img: details1,
        },
    ]
  return (
   <>
    <Header />
    {/* <!-- Universal Banner Sec Start Here --> */}
<section className="universal_banner">
    <div className="container">
        <div className="page_details">
            <h2 className="title">CHECKOUT</h2>
            <ul className="breadcrumbs">
                <li><Link to="/">HOME PAGE</Link></li>
                <li className="active">CHECKOUT</li>
            </ul>
        </div>
    </div>
</section>
{/* <!-- Universal Banner Sec End Here --> */}

{/* <!-- Thank You Sec Start Here --> */}
<section className="thank_you">
    <div className="container">
        <div className="heading_wrapper">
            <h2 className="title">Thank You for Shopping!!</h2>
            <h4 className="sub-title">Your Order is confirmed</h4>
        </div>
        <div className="order-number">
            <h4>OrderNo # 1084598423154</h4>
        </div>
        <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
                <div className="thank_you_box">
                    <div className="cart-product-detail">
                        <div className="checkout-products">
                            {
                                orderData?.map((item,index)=>{
                                    return(
                                        <div className="products" key={index}>
                                        <div className="abt-product">
                                            <div className="img-box">
                                                <figure><img src={item?.img} alt="123" /></figure>
                                            </div>
                                            <div className="pro-dtl">
                                                <h5 className="name">Venison Welington <br/>
                                                    Small</h5>
                                                <ul className="variations">
                                                    <li>Syrup / Sauce / Extra Bread</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-price">
                                            <h4 className="price">$539.00</h4>
                                        </div>
                                    </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className="button-group">
                        <Link to="/" className="btn">CONTINUE SHOPPING 
                            <img src={arrow} className="img-fluid"/></Link>
                    </div>
                </div>
            </div>
            <div className="col-md-2"></div>
        </div>
    </div>
</section>
{/* <!-- Thank You Sec End Here --> */}
    <Footer />
   </>
  )
}

export default ThankYou