import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/contact.css";
import { colSep, contact_form } from "../../constant";
import { toast } from "react-toastify";
import { GetSettingPages, PostContactUs } from "../../network/Network";
import { Link } from "react-router-dom";
import GoogleMapComp from "../../components/Map/GoogleMapComp";

function Contactus() {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [settingData, setSettingData] = useState([]);

	const ContactUsHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!firstName || !lastName || !email || !phone || !message) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		if (phone.length < 10) {
			toast.error("The contact number must be at least 10 characters.");
			setLoading(false);
			return;
		}
		if (firstName.length < 2 || firstName.length > 15) {
			toast.error("First name limit is min 3 and max 15 characters");
			setLoading(false);
			return;
		}
		if (lastName.length < 2 || lastName.length > 15) {
			toast.error("Last name limit is min 3 and max 15 characters");
			setLoading(false);
			return;
		}
		if (message.length < 20) {
			toast.error("The message must be at least 20 characters");
			setLoading(false);
			return;
		}
		let data = {
			first_name: firstName,
			last_name: lastName,
			email: email,
			contact_number: phone,
			description: message,
		};
		console.log(data);
		PostContactUs(data)
			.then((res) => {
				setLoading(false);
				console.log(res);
				toast.success("Successfully send");
				setFirstName("");
				setLastName("");
				setEmail("");
				setPhone("");
				setMessage("");
			})
			.catch((err) => {
				toast.error(err?.response?.data?.errors);
				setLoading(false);
				console.log(err);
			});
	};

	useEffect(() => {
		GetSettingPages()
			.then((res) => {
				console.log(res);
				setSettingData(res?.data?.response?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Header />
			{/* <!-- Universal Banner Sec Start Here --> */}
			<section className="universal_banner">
				<div className="container">
					<div className="page_details">
						<h2 className="title">Contact</h2>
						<ul className="breadcrumbs">
							<li>
								<Link to="/">HOME PAGE</Link>
							</li>
							<li className="active">Contact</li>
						</ul>
					</div>
				</div>
			</section>
			{/* <!-- Universal Banner Sec End Here --> */}

			{/* <!-- Contact Info Start Here --> */}
			<section className="contact_info">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">RESTAURANT LOCATION</h5>
								<p>{settingData?.address}</p>
								
							</div>
						</div>
						<div className="col-lg-4">
							<div className="center_box">
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
								<div className="info_box">
									{/* <h5 className="title">BOOK YOUR TABLE</h5> */}
									<p>
										<a className="phone" href="">
											{settingData?.contact_phone}
										</a>
										{/* <br />
										<a href="" className="email">
											{settingData?.contact_email}
										</a> */}
									</p>
									<p>{settingData?.contact_email1}</p>
								</div>
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">OPENING HOURS</h5>
								<p>
									<ruby>
										<span>{settingData?.hours}</span>
									</ruby>
								</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">RESTAURANT LOCATION</h5>
								<p>{settingData?.address2}</p>
								
							</div>
						</div>
						<div className="col-lg-4">
							<div className="center_box">
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
								<div className="info_box">
									{/* <h5 className="title">BOOK YOUR TABLE</h5> */}
									<p>
										<a className="phone" href="">
											{settingData?.contact_phone2}
										</a>
										{/* <br />
										<a href="" className="email">
											{settingData?.contact_email}
										</a> */}
									</p>
									<p>{settingData?.contact_email2}</p>
								</div>
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">OPENING HOURS</h5>
								<p>
									<ruby>
										<span>{settingData?.hours2}</span>
									</ruby>
								</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">RESTAURANT LOCATION</h5>
								<p>{settingData?.address3}</p>
								
							</div>
						</div>
						<div className="col-lg-4">
							<div className="center_box">
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
								<div className="info_box">
									{/* <h5 className="title">BOOK YOUR TABLE</h5> */}
									<p>
										<a className="phone" href="">
											{settingData?.contact_phone3}
										</a>
										{/* <br />
										<a href="" className="email">
											{settingData?.contact_email}
										</a> */}
									</p>
									<p>{settingData?.contact_email3}</p>
								</div>
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">OPENING HOURS</h5>
								<p>
									<ruby>
										<span>{settingData?.hours3}</span>
									</ruby>
								</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">RESTAURANT LOCATION</h5>
								<p >{settingData?.address4}</p>
								
							</div>
						</div>
						<div className="col-lg-4">
							<div className="center_box">
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
								<div className="info_box">
									{/* <h5 className="title">BOOK YOUR TABLE</h5> */}
									<p>
										<a className="phone" href="">
											{settingData?.contact_phone4}
										</a>
										{/* <br />
										<a href="" className="email">
											{settingData?.contact_email}
										</a> */}
									</p>
									<p>{settingData?.contact_email4}</p>
								</div>
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">OPENING HOURS</h5>
								<p>
									<ruby>
										<span>{settingData?.hours4}</span>
									</ruby>
								</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">RESTAURANT LOCATION</h5>
								<p>{settingData?.address5}</p>
								
							</div>
						</div>
						<div className="col-lg-4">
							<div className="center_box">
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
								<div className="info_box">
									{/* <h5 className="title">BOOK YOUR TABLE</h5> */}
									<p>
										<a className="phone" href="">
											{settingData?.contact_phone5}
										</a>
										{/* <br />
										<a href="" className="email">
											{settingData?.contact_email}
										</a> */}
									</p>
									<p>{settingData?.contact_email5}</p>
								</div>
								<div className="seprator">
									<figure>
										<img src={colSep} className="img-fluid" />
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="info_box">
								<h5 className="title">OPENING HOURS</h5>
								<p>
									<ruby>
										<span>{settingData?.hours5}</span>
									</ruby>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Contact Info End Here --> */}

			{/* <!-- Contact Query Sec Start Here --> */}
			<section className="contact_query">
				<div className="container">
					<div className="google_map">
						<GoogleMapComp />
						{/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.642359732907!2d-122.44995808474766!3d37.79842041857194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085872b3c564e7b%3A0xb06d0c87838b908c!2s9465%20Lombard%20St%2C%20San%20Francisco%2C%20CA%2094123%2C%20USA!5e0!3m2!1sen!2s!4v1649697953125!5m2!1sen!2s"
              width="100%"
              height="400"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
					</div>
				</div>
			</section>
			{/* <!-- Contact Query Sec End Here --> */}

			{/* <!-- Contact Form Sec Start Here --> */}
			<section className="contact_form">
				<div className="container">
					<div className="contact_box">
						<div className="row align-items-center">
							<div className="col-lg-5">
								<div className="img_box">
									<figure>
										<img src={contact_form} className="img-fluid" />
									</figure>
								</div>
							</div>
							<div className="col-lg-7">
								<div className="contact_details">
									<div className="heading_wrapper">
										<h3>Contact US</h3>
										{/* <p>
											Your email address will not be published.required fields
											are marked *
										</p> */}
									</div>
									<form>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<input
														type="text"
														className="form-control"
														placeholder="First Name"
														value={firstName}
														onChange={(e) => setFirstName(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<input
														type="text"
														className="form-control"
														placeholder="Last Name"
														value={lastName}
														onChange={(e) => setLastName(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<input
														type="email"
														className="form-control"
														placeholder="Email Address"
														value={email}
														onChange={(e) => setEmail(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<input
														type="tel"
														className="form-control"
														placeholder="Contact Number"
														value={phone}
														onChange={(e) => setPhone(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<textarea
														placeholder="Message"
														className="form-control"
														value={message}
														onChange={(e) => setMessage(e.target.value)}
													></textarea>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<button
														className="btn"
														onClick={(e) => ContactUsHandler(e)}
														disabled={loading}
													>
														{loading ? "Loading.." : "SUBMIT NOW"}
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Contact Form Sec End Here --> */}

			<Footer />
		</>
	);
}

export default Contactus;
