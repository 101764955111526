import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function InputAutoComplete(props) {
  const {
    // setPropData,
    // setCoordinates,
    setZip,
    setCity,
    setState,
    setCountry,
    setAddress,
    // setSendCord,
    setCompleteAddress,
    completeAddress,
  } = props;

  const [addressData, setAddressData] = React.useState("");

  const handleSelect = async (value) => {
    console.log(value);
    setCompleteAddress(value)
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddressData(value);
    console.log("test results", results)
    // setPropData(results);
    // setCoordinates(latLng);
    // setSendCord(latLng);
    let data = [];
    console.log(results[0].address_components[0].types[0]);
    for (let i = 0; i <= results[0].address_components.length; i++) {
      if (results[0]?.address_components[i]?.types[0] === "street_number") {
        data.push({ street: results[0]?.address_components[i].long_name });
      } else if (results[0]?.address_components[i]?.types[0] === "country") {
        setCountry(results[0]?.address_components[i].long_name);
      } else if (
        results[0]?.address_components[i]?.types[0] === "postal_code"
      ) {
        setZip(results[0]?.address_components[i].long_name);
      } else if (results[0]?.address_components[i]?.types[0] === "route") {
        setAddress(results[0]?.address_components[i].long_name);
      } else if (
        results[0]?.address_components[i]?.types[0] ===
        "administrative_area_level_1"
      ) {
        setState(results[0]?.address_components[i].long_name);
      } else if (
        results[0]?.address_components[i]?.types[0] ===
        "administrative_area_level_2"
      ) {
        setCity(results[0]?.address_components[i].long_name);
      }
    }
    console.log(data);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={addressData}
        onChange={setAddressData}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {/* <p>Latitude: {coordinates.lat}</p>
            <p>Longitude: {coordinates.lng}</p> */}

            <input
              {...getInputProps({ placeholder: "Type address" })}
              className="autoInput"
            />

            <div>
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { style })}
                    className="autocomplete-dropdown-container"
                  >
                    <p>{suggestion.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}


