import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import "../../assets/css/our-story.css";
import { blackArrow, chef1, chef2, chef3, expertise1, expertise2, expertise3, expertise4, plateIcon, separator, story1, story2 } from '../../constant';
import TeamCard from '../../components/Card/TeamCard';
import ServiceCard from '../../components/Card/ServiceCard';

function OurStory() {
    const teamData = [
        {
            id:1,
            img: chef1,
        },
        {
            id:1,
            img: chef2,
        },
        {
            id:1,
            img: chef3,
        },
    ]

    const serviceData = [
        {
            id:1,
            img: expertise1,
            title: "Fresh Products"
        },
        {
            id:2,
            img: expertise2,
            title: "Skilled Chefs"
        },
        {
            id:3,
            img: expertise3,
            title: "Services"
        },
        {
            id:4,
            img: expertise4,
            title: "Quality"
        },
    ]
  return (
   <>
    <Header />
    {/* <!-- Universal Banner Sec Start Here --> */}
<section className="universal_banner">
    <div className="container">
        <div className="page_details">
            <h2 className="title">Our Story</h2>
            <ul className="breadcrumbs">
                <li><a href="index.php">HOME PAGE</a></li>
                <li className="active">OUR STORY</li>
            </ul>
        </div>
    </div>
</section>
{/* <!-- Universal Banner Sec End Here --> */}

{/* <!-- Our Story Sec Start Here --> */}
<section className="story_sec">
    <div className="container">
        <div className="heading_wrapper">
            <h5 className="sub-title">WHOWEARE</h5>
            <h2 className="title">We've been creating delicious
                food for over 25 years</h2>
            <div className="seprator">
                <figure><img src={separator} className="img-fluid" /></figure>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-5">
                <div className="content_wrapper">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, seddo eiusmod tempor incididunt ut labore et
                        dolore magna aliquaUt enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore
                        eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia
                        deseruntmollit anim id est laborum.
                    </p>
                    <h5>Experience the award-winning kitchens led by
                        expert chefs and culinarians</h5>
                    <div className="icon_with_txt">
                        <div className="img_box">
                            <figure><img src={plateIcon} className="img-fluid" /></figure>
                        </div>
                        <div className="text">
                            <h6>Book Through Call</h6>
                            <a href="tel:+84(400)3335526" className="tel">+84 (400) 333 5526</a>
                        </div>
                    </div>
                    <div className="button-group">
                        <a href="order-online.php" className="btn">ORDER ONLINE 
                            <img src={blackArrow} className="img-fluid" /></a>
                    </div>
                </div>
            </div>
            <div className="col-lg-7">
                <div className="row">
                    <div className="col-md-6">
                        <div className="img_box">
                            <figure><img src={story1} className="img-fluid" /></figure>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="img_box">
                            <figure><img src={story2} className="img-fluid" /></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- Our Story Sec Start Here --> */}

{/* <!-- Expertise Sec Start Here --> */}
<section className="expertise">
    <div className="container">
        <div className="heading_wrapper">
            <h5 className="sub-title">WHY CHOOSE US </h5>
            <h2 className="title">Exquisite Services</h2>
            <div className="seprator">
                <figure><img src={separator} className="img-fluid" /></figure>
            </div>
        </div>
        <div className="row">
            {
                serviceData?.map((item,index)=>{
                    return(
                        <ServiceCard 
                            item={item}
                            index={index}
                        />
                    )
                })
            }
        </div>
    </div>
</section>
{/* <!-- Expertise Sec End Here --> */}

{/* <!-- Milestone Sec Start Here --> */}
<section className="milestone_sec">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-md-6">
                <div className="mile_box">
                    <div className="number">
                        <h2>1820</h2>
                    </div>
                    <div className="subnjects">
                        <p>Kind dishes & drinks</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="mile_box">
                    <div className="number">
                        <h2>963</h2>
                    </div>
                    <div className="subnjects">
                        <p>Special Order</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="mile_box">
                    <div className="number">
                        <h2>1580</h2>
                    </div>
                    <div className="subnjects">
                        <p>Great Moments</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="mile_box">
                    <div className="number">
                        <h2>2130</h2>
                    </div>
                    <div className="subnjects">
                        <p>Happy Customers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- Milestone Sec End Here --> */}

{/* <!-- Team Sec Start Here --> */}
<section className="team_sec">
    <div className="container">
        <div className="heading_wrapper">
            <h5 className="sub-title">AMAZING TEAM</h5>
            <h2 className="title">Exquisite Services</h2>
            <div className="seprator">
                <figure><img src={separator} className="img-fluid" /></figure>
            </div>
        </div>
        <div className="row">
            {
                teamData?.map((item,index)=>{
                    return(
                       <TeamCard 
                            item={item}
                            index={index}
                       />
                    )
                })
            }
        </div>
    </div>
</section>
{/* <!-- Team Sec End Here --> */}
    <Footer />
   </>
  )
}

export default OurStory