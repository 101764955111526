import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	facebook2,
	footerLogo,
	instagram2,
	mrLogo,
	snapchat2,
	tiktok2,
} from "../../constant";
import { GetAllCategories, GetSettingPages } from "../../network/Network";
import { CategoriesData } from "../../redux/actions/AuthActions";
import MenuPdf from '../../assets/pdf/Mr. Stacks Main Menu.pdf'
import CatePdf from '../../assets/pdf/Mr. Stacks Catering Menu.pdf'

function Footer() {
	const dispatch = useDispatch();
	const CategoriesData = useSelector(
		(state) => state.AuthReducer.categoriesData,
	);
	const [settingData, setSettingData] = useState([]);

	useEffect(() => {
		GetSettingPages()
			.then((res) => {
				setSettingData(res?.data?.response?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	// Menu Categories
	useEffect(() => {
		GetAllCategories()
			.then((res) => {
				dispatch(CategoriesData(res?.data?.response?.data?.data));
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-sm-3">
						<div className="footer-widget-1-wrapper">
							<Link to="/">
								<img src={mrLogo} className="img-fluid" alt="" />
							</Link>
							<div className="social-icon-wrapper">
								<ul>
									<li>
										<a href={settingData?.facebook} target="_blank">
											<img src={facebook2} alt="" />
										</a>
									</li>
									<li>
										<a href={settingData?.instagram} target="_blank">
											<img src={instagram2} alt="" />
										</a>
									</li>
									<li>
										<a href={settingData?.tiktok} target="_blank">
											<img src={tiktok2} alt="" />
										</a>
									</li>
									<li>
										<a href={settingData?.snapchat} target="_blank">
											<img src={snapchat2} alt="" />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-3">
						<div className="footer-widget-2-wrapper footer-menus">
							<div className="footer-menu-heading">
								<h5>Our Menu</h5>
							</div>
							<div className="footer-menu-links">
								<ul>
								<li><a href={MenuPdf} target="_blank" >
								Main Menu
									</a>
								</li>
								<li>
									<a href={CatePdf} target="_blank">
										Catering Menu
									</a>
								</li>
									{/* {CategoriesData?.footer?.map((item, index) => {
										return (
											<>
												{index < 4 ? (
													<li key={index}>
														<Link to={`/menu`} state={{ data: item?.id }}>
															{item?.title}
														</Link>
													</li>
												) : null}
											</>
										);
									})} */}
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-3">
						<div className="footer-widget-3-wrapper footer-menus">
							<div className="footer-menu-heading">
								<h5>QUICK LINKS</h5>
							</div>
							<div className="footer-menu-links">
								<ul>
									{/* <!-- <li><a href="#">OUR STORY</a></li> --> */}
									<li>
										<Link to="/careers">CAREERS</Link>
									</li>
									<li>
										<Link to="/contact-us">CONTACT</Link>
									</li>
									{/* <li>
                    <Link to="/news-letter">NEWSLETTER</Link>
                  </li> */}
								</ul>
							</div>
						</div>
					</div>
					{/* <div className="col-lg-2 col-sm-2">
						<div className="footer-widget-4-wrapper footer-menus">
							<div className="footer-menu-heading">
								<h5>USEFUL LINKS</h5>
							</div>
							<div className="footer-menu-links">
								
							</div>
						</div>
					</div> */}
					<div className="col-lg-3 col-sm-3">
						<div className="footer-widget-4-wrapper footer-menus">
							<div className="footer-menu-heading">
								<h5>OTHERS</h5>
							</div>
							<div className="footer-menu-links">
								<ul>
									<li>
										<Link to="/term-condition">TERMS AND CONDITIONS</Link>
									</li>
									<li>
										<Link to="/privacy-policy">PRIVACY POLICY</Link>
									</li>
									{/* <li>
                    <Link to="/cancel-policy">CANCEL POLICY</Link>
                  </li> */}
									{/* <li><Link to="/loyalty-point-policy">LOYALTY POINTS POLICY</Link></li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12 text-center">
						<div className="copy-right">
							<p>{settingData?.copyright}</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
