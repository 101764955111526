import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/blog-detail.css";
import { blogs1, blogs2, blogs3, comma, separator } from "../../constant";
import { GetBlogDetails } from "../../network/Network";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

function BlogDetails() {
  let currentUrl = window.location.href.split("/");
  console.log(currentUrl[4]);
  const [blogDetails, setBlogDetails] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true)
    GetBlogDetails(currentUrl[4])
      .then((res) => {
        console.log(res);
        setBlogDetails(res?.data?.response?.data);
        setSpinLoad(false)
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false)
      });
  }, []);
  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">BLOG</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">BLOG</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {/* <!-- Blog Detail Sec Start Here --> */}
      <section className="blog_detail">
        {
            spinLoad ? (
                 <>
               <div className="m-5">
                <Skeleton count={5} />
              </div>
              <div className="m-5">
                <Skeleton count={5} />
              </div>
              <div className="m-5">
                <Skeleton count={5} />
              </div>   
            </>
            ) : (
                <>
                           <div className="container">
          <div className="heading_wrapper">
            <h5 className="sub-title">RECOMMENDATIONS </h5>
            <h2 className="title">{blogDetails?.title}</h2>
            <div className="seprator">
              <figure>
                <img src={separator} className="img-fluid" />
              </figure>
            </div>
            <div className="details">
              <p className="date">{moment(blogDetails?.updated_at).format('MMMM Do YYYY')}</p>
              <p className="posted_by"> BY {blogDetails?.added_by}</p>
            </div>
          </div>
          <div className="blog_content">
            <p
              className="fw-400"
              dangerouslySetInnerHTML={{ __html: blogDetails?.description }}
            />
        </div>
        </div>
                </>
            )
        }
 
      </section>
      {/* <!-- Blog Detail Sec End Here --> */}
      <Footer />
    </>
  );
}

export default BlogDetails;
