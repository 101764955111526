import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { arrow, notFound } from "../../constant";

function BlogCard(props) {
  const { item, index } = props;
  return (
    <>
      <div className="blog_box" key={index}>
        <Link to={`/blog-details/${item?.id}`}>
          <div className="img_box">
            <figure>
              {item?.image == null ? (
                <img src={notFound} className="img-fluid" />
              ) : (
                <img src={item?.image} className="img-fluid" />
              )}
            </figure>
          </div>
          <div className="blog_content">
            <div className="details">
              <div className="date">
                <p>{moment(item?.updated_at).format('MMMM Do YYYY')}</p>
              </div>
              <div className="postedby">
                <p> BY {item?.added_by} </p>
              </div>
            </div>
            <div className="name">
              <h2>{item?.title}</h2>
            </div>
            <div className="description">
              {/* <p
                                        className="fw-400"
                                        dangerouslySetInnerHTML={{ __html: item?.description }}
                                    /> */}
              <p>{item?.short_description}</p>
            </div>
            <div className="line"></div>
            <div className="button-group">
              <Link to={`/blog-details/${item?.id}`} className="btn">
                READ MORE <img src={arrow} />
              </Link>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}

export default BlogCard;
