import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/menu.css";
import {
  bigMenu1,
  bigMenu2,
  breakfast1,
  breakfast2,
  breakfast3,
  breakfast4,
  notFound,
  separator,
} from "../../constant";
import MenuCard from "../../components/Card/MenuCard";
import { GetAllMenu, GetMenuDetail } from "../../network/Network";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function MenuDetail() {
  const dispatch = useDispatch();
  let currentUrl = window.location.href.split("/");
  const CategoriesDataRedux = useSelector(
    (state) => state.AuthReducer.categoriesData
  );
  const allStates = useSelector((state) => state.CartReducer.cartData);
  const location = useLocation();
  const [productData, setProductData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSpinLoad(true);
    GetMenuDetail(currentUrl[4])
      .then((res) => {
        console.log("detail", res);
        setProductData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, []);

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">Menu DETAILS</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">MENU DETAILS</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {/* <!-- Menus Sec Start Here --> */}
      <section className="menu_list">
        <div className="container-fluid">
          <div className="heading_wrapper">
            <h5 className="sub-title">RCOMMENDATIONS</h5>
            <h2 className="title">SPECIAL MENU</h2>
          </div>
          <div className="row menu-detail">
            {spinLoad ? (
              <>
                {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                  return (
                    <div className="col-md-12 mt-3" key={index}>
                      <Skeleton count={5} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="col-md-6">
                  <div className="img-div">
                    <img
                      src={productData?.image}
                      alt=""
                      className="image-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right">
                    <h2 className="p-title">{productData?.title}</h2>
                    <h2 className="price">${productData?.price}</h2>
                    <p>{productData?.description}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      {/* <!-- Menus Sec End Here --> */}
      <Footer />
    </>
  );
}

export default MenuDetail;
