import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { arrow, breakfast2, separator } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle,faFileLines } from "@fortawesome/free-solid-svg-icons";

function ApplyNow(props) {
  const { setIsOpenModal, isOpenModal,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    address,
    setAddress,
    city,
    setCity,
    statedata,
    setStateData,
    postalCode,
    setPostalCode,
    loading,
    UploadResume,
    mediaFile,
    handleImageUpload,
    } = props;

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="Addtocart-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModal(false)}
        />
        <div className="inner-content">
        <div id="applyNow">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
            <div className="modal-body">
                <div className="heading_wrapper">
                    <h5 className="sub-title">WORK WITH US</h5>
                    <h2 className="title">APPLICATION FORM</h2>
                    <div className="seprator">
                        <figure><img src={separator} className="img-fluid" /></figure>
                    </div>
                </div>
                <form>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="First Name" 
                                    className="form-control" 
                                    value={firstName}
                                    onChange={(e)=>setFirstName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="Last Name" 
                                    className="form-control" 
                                    value={lastName}
                                    onChange={(e)=>setLastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="email" 
                                    placeholder="Email Address" 
                                    className="form-control" 
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="tel" 
                                    placeholder="Contact Number" 
                                    className="form-control" 
                                    value={phone}
                                    onChange={(e)=>setPhone(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="Address" 
                                    className="form-control" 
                                    value={address}
                                    onChange={(e)=>setAddress(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="City"
                                    className="form-control"
                                    value={city}
                                    onChange={(e)=>setCity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="State/Province" 
                                    className="form-control" 
                                    value={statedata}
                                    onChange={(e)=>setStateData(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    placeholder="Postal Code" 
                                    className="form-control" 
                                    value={postalCode}
                                    onChange={(e)=>setPostalCode(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="attach_file">
                                <label for="cv">Attach Cv ( PDF)</label>
                                {/* <input type="file" id="cv" accept=".pdf" className="form-control" /> */}
                                <input
                                    type="file"
                                    id="cv"
                                    accept="application/pdf"
                                    // application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    onChange={handleImageUpload}
                                    className="form-control"
                                    multiple="false"
                                />
                                    {
                                        mediaFile ? (  
                                            <FontAwesomeIcon
                                                icon={faFileLines}
                                                className="file-icon"
                                            />
                                        ) : (null)
                                    }
                                  

                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="button-group">
                                <button 
                                    className="btn" 
                                    type="submit" data-dismiss="modal"
                                    onClick={(e) => UploadResume(e)}
                                    disabled={loading}
                                  >
                                    {loading ? "Loading.." : "APPLY JOB"}
                         
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
        </div>
      </Modal>
    </>
  );
}

export default ApplyNow;
