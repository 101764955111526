import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/order-online.css";
import { arrow, separator } from "../../constant";
import MenuSlider from "../../components/Slider/MenuSlider";
import AddToCartModal from "../../components/Modal/AddToCartModal";
import { Link } from "react-router-dom";

function RestaurantMenu() {
  const [ isOpenModal, setIsOpenModal ] = useState(false)
  
  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section class="universal_banner">
        <div class="container">
          <div class="page_details">
            <h2 class="title">Restaurant Menu</h2>
            <ul class="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li class="active">ORDER ONLINE</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {/* <!-- Order Online Sec Start Here --> */}
      <section class="order_online">
        <div class="container">
          <div class="heading_wrapper">
            <h5 class="sub-title">RECOMMENDATIONS</h5>
            <h2 class="title">Special items</h2>
            <div class="seprator">
              <figure>
                <img src={separator} class="img-fluid" />
              </figure>
            </div>
          </div>
          <div class="filter_box">
            <div class="row align-items-center">
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Restaurant Location"
                    class="form-control colored frst"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <select class="form-control colored scnd">
                    <option>Pick Up or Delivery</option>
                    <option>lorem1</option>
                    <option>lorem2</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <input type="date" class="form-control" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <input type="time" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="ordered_products">
            <MenuSlider 
              setIsOpenModal={setIsOpenModal}
            />
          </div>
        </div>
      </section>
      {/* <!-- Order Online Sec End Here --> */}

      {/* <!-- Order Online Sec Start Here --> */}
      <section class="order_online">
        <div class="container">
          <div class="heading_wrapper">
            <h5 class="sub-title">RECOMMENDATIONS</h5>
            <h2 class="title">Special items</h2>
            <div class="seprator">
              <figure>
                <img src={separator} class="img-fluid" />
              </figure>
            </div>
          </div>
          <div class="ordered_products">
            <MenuSlider 
              setIsOpenModal={setIsOpenModal}
            />
          </div>
        </div>
      </section>
      {/* <!-- Order Online Sec End Here --> */}

  

      <Footer />
      <AddToCartModal setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} />
    </>
  );
}

export default RestaurantMenu;
