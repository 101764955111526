import React from 'react'
import { SpinnerCircular } from "spinners-react";

function SpinLoader() {
  return (
    <div className="loader-container">
        <SpinnerCircular size="80px" color="#d60012" />
  </div>
  )
}

export default SpinLoader