import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import "../../assets/css/sign-up.css";
import { rightArrow } from '../../constant';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { signUp } from '../../redux/actions/AuthActions';

function Signup() {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ phone, setPhone] = useState("")
    const [ confirmPassword, setConfirmPassword ] = useState("")
    const [ firstName, setFirstName ] = useState("")
    const [ lastName, setLastName ] = useState("")
    const [btnLoading, setBtnLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleRememeberMe = (event) => {
        setChecked(!checked);
      };

    const SignUpHandler = async (e) => {
        e.preventDefault();
        setBtnLoading(true);
        if (
          !firstName ||
          !lastName ||
          !email ||
          !phone ||
          !password ||
          !confirmPassword
        ) {
          toast.error("Please Enter All Fields");
          setBtnLoading(false);
          return;
        }
        if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
          )
        ) {
          toast.error("Invalid Email");
          setBtnLoading(false);
          return;
        } 
        if (!checked){
            toast.error("Please check our term and conditions");
            setBtnLoading(false);
            return;
        }
        if(phone?.length < 10){
            toast.error("The phone no must be at least 10 characters");
            setBtnLoading(false);
            return;
        }
        else {
          let data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_no: phone,
            password: password,
            confirm_password: confirmPassword,
          };
          console.log(data);
          setBtnLoading(false);
          let send = await dispatch(signUp(data, Navigate));
        }
      };

  return (
    <>
        <Header />
        {/* <!-- Universal Banner Sec Start Here --> */}
<section className="universal_banner">
    <div className="container">
        <div className="page_details">
            <h2 className="title">SIGN UP</h2>
            <ul className="breadcrumbs">
                <li><Link to="/">HOME PAGE</Link></li>
                <li className="active">SIGN UP</li>
            </ul>
        </div>
    </div>
</section>
{/* <!-- Universal Banner Sec End Here --> */}
{/* <!-- breadcrumbs sec ends here --> */}
<section className="checkout">
    <div className="container">
        <form>
            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 kip">
                    <div className="first-top-bar">
                        <h3>Sign Up</h3>
                        <p>Already Have an account? <Link to="/login">Log in</Link> </p>
                    </div>
                    <div className="add-new-address">
                        {/* <!-- <div className="form-group mb-4">
                            <label for="method">Shipment Method</label>
                            <select id="method" className="form-control">
                                <option selected="">Select Shipment method</option>
                                <option>Select Shipment method 1</option>
                                <option>Select Shipment method 2</option>
                                <option>Select Shipment method 3</option>
                            </select>
                        </div> --> */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="fname">First Name</label>
                                    <input 
                                        type="text" 
                                        id="fname" 
                                        className="form-control" 
                                        value={firstName}
                                        onChange={(e)=>setFirstName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="lname">Last Name</label>
                                    <input 
                                        type="text" 
                                        id="lname" 
                                        className="form-control" 
                                        value={lastName}
                                        onChange={(e)=>setLastName(e.target.value)}    
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="phone">Phone</label>
                                    <input 
                                        type="number" 
                                        id="phone" 
                                        className="form-control" 
                                        value={phone}
                                        onChange={(e)=>setPhone(e.target.value)}  
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="email">Email</label>
                                    <input 
                                        type="text" 
                                        id="email" 
                                        className="form-control" 
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}  
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="Password">Password</label>
                                    <input 
                                        type="password" 
                                        id="Password" 
                                        className="form-control" 
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}      
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="Password">Confirm Password</label>
                                    <input 
                                        type="password" 
                                        id="Password" 
                                        className="form-control" 
                                        value={confirmPassword}
                                        onChange={(e)=>setConfirmPassword(e.target.value)} 
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="form-check">
                                            <input 
                                                type="checkbox" 
                                                className="form-check-input" 
                                                id="exampleCheck1"
                                                value={checked} 
                                                onChange={(e) => handleRememeberMe(e)}
                                            />
                                            <label className="form-check-label" for="exampleCheck1">Agree to Terms & conditions</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <div className="add-new-method">

                                            <button 
                                                type="button" 
                                                className="btn" 
                                                onClick={(e) => SignUpHandler(e)}
                                                disabled={btnLoading}
                                            >            
                                                {btnLoading ? "Loading.." :  "SIGN UP"} &nbsp; 
                                                <img src={rightArrow} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="payment-details">

                        <div className="saved-payment-method">

                            {/* <!-- Saved payments Method --> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-3"></div>
            </div>
        </form>
    </div>
</section>
        <Footer />
    </>
  )
}

export default Signup