import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/job-detail.css";
import {
  arrow,
  icon1,
  icon2,
  icon3,
  icon4,
  job1,
  jobIcon1,
  jobIcon2,
  jobIcon3,
  jobIcon4,
  jobIcon5,
  jobIcon6,
  jobIcon7,
  jobIcon8,
} from "../../constant";
import ApplyNow from "../../components/Modal/ApplyNow";
import { GetCareerDetails, PostResume } from "../../network/Network";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

function JobDetails() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [mediaFile, setMediaFile] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  let currentUrl = window.location.href.split("/");
  console.log(currentUrl[4]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [statedata, setStateData] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true)
    GetCareerDetails(currentUrl[4])
      .then((res) => {
        console.log(res);
        setJobDetails(res?.data?.response?.data);
        setSpinLoad(false)
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false)
      });
  }, [currentUrl[4]]);

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e?.target?.files[0];
    reader.onloadend = () => {
      setMediaFile(file);
      console.log("media", file);
    };
    reader?.readAsDataURL(file);
  };

  const UploadResume = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(mediaFile);
    if(!firstName || !lastName || !email || !phone || !address || !city || !statedata || !postalCode || !jobDetails?.id ){
      toast.error("Please Enter All Fields")
      setLoading(false);
      return
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if(!mediaFile){
      toast.error("Please Upload Cv")
      setLoading(false);
      return
    }
    if(phone.length < 10 || phone.length > 15  ){
      toast.error("Phone no must be greater than 10 and less than 15 characters.")
      setLoading(false);
      return
    }
    let data = await new FormData();
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("email", email);
    data.append("phone_no", phone);
    data.append("address", address);
    data.append("city", city);
    data.append("state", statedata);
    data.append("postal_code", postalCode);
    data.append("career_id", jobDetails?.id);
    data.append("attach", mediaFile);
    console.log(data);
    PostResume(data)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setLoading(false);
        setMediaFile("");
        setIsOpenModal(false)
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhone("")
        setCity("")
        setStateData("")
        setPostalCode("")
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />

      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">FEATURED JOBS</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">CAREERS</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {spinLoad ? (
            <>
               <div className="m-5">
                <Skeleton count={5} />
              </div>
              <div className="m-5">
                <Skeleton count={5} />
              </div>
              <div className="m-5">
                <Skeleton count={5} />
              </div>   
            </>
        ) : (
          <>
                 <section className="job_detail">
        <div className="container">
          <div className="job_box">
            <div className="img_box">
              <figure>
                <img src={jobDetails?.image} className="img-fluid" />
              </figure>
            </div>
            <div className="job_flex">
              <div className="job_details">
                <a href="#">
                  <h4 className="title">
                    {jobDetails?.title} ( indoor dining){" "}
                    <span className="type">FEATURED</span>
                  </h4>
                </a>
                <p className="location">{jobDetails?.address}</p>
                <div className="all_details">
                  <div className="icon_with_text">
                    <figure>
                      <img src={icon1} className="img-fluid" />
                    </figure>
                    <h6>Kitchen</h6>
                  </div>
                  <div className="icon_with_text">
                    <figure>
                      <img src={icon2} className="img-fluid" />
                    </figure>
                    <h6>{jobDetails?.country}</h6>
                  </div>
                  <div className="icon_with_text">
                    <figure>
                      <img src={icon3} className="img-fluid" />
                    </figure>
                    <h6>{jobDetails?.updated_at}</h6>
                  </div>
                  <div className="icon_with_text">
                    <figure>
                      <img src={icon4} className="img-fluid" />
                    </figure>
                    <h6>{jobDetails?.package}</h6>
                  </div>
                </div>
                <div className="button-group">
                  <button className="full_time">{jobDetails?.job_type}</button>
                  {
                    jobDetails?.is_urgent ? <button className="urgent">Urgent</button> : null
                  }
                  
                </div>
              </div>
              <div className="buttons-group">
                <div className="end_date">
                  <p>
                    Application ends : <span>{moment(jobDetails?.expiry_date).format('MMMM Do YYYY')}</span>
                  </p>
                </div>
                <div className="appply_btn">
                  <button
                    className="btn"
                    type="button"
                    // data-toggle="modal" data-target="#applyNow"
                    onClick={() => setIsOpenModal(true)}
                  >
                    APPLY NOW <img src={arrow} className="img-fluid" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="job_description">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="content_wrapper">
                <div className="heading">
                  <h4>Kitchen Staff Job Description </h4>
                </div>
                <p>{jobDetails?.description}</p>
              </div>
              <div className="kitchen_responsibilities">
                <div className="heading">
                  <h4>Kitchen Staff Responsibilities</h4>
                </div>
                <p
                  className="productDesc"
                  dangerouslySetInnerHTML={{
                    __html: jobDetails?.responsibilities,
                  }}
                />
              </div>
              <div className="requirements">
                <div className="heading">
                  <h4>Kitchen Staff Requirements</h4>
                </div>
                <p
                  className="productDesc"
                  dangerouslySetInnerHTML={{
                    __html: jobDetails?.requirements,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="job_overview">
                <div className="heading">
                  <h4>Job Overview</h4>
                </div>
                <ul className="details">
                  <li>
                    <div className="img_box">
                      <figure>
                        <img src={jobIcon1} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="detail">
                      <h5>Date Posted</h5>
                      <p>{jobDetails?.updated_at}</p>
                    </div>
                  </li>
                  <li>
                    <div className="img_box">
                      <figure>
                        <img src={jobIcon2} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="detail">
                      <h5>Location</h5>
                      <p>{jobDetails?.country}</p>
                    </div>
                  </li>
                  <li>
                    <div className="img_box">
                      <figure>
                        <img src={jobIcon3} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="detail">
                      <h5>Offered Salary</h5>
                      <p>{jobDetails?.package}</p>
                    </div>
                  </li>
                  <li>
                    <div className="img_box">
                      <figure>
                        <img src={jobIcon4} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="detail">
                      <h5>Expiration Date</h5>
                      <p>{jobDetails?.expiry_date}</p>
                    </div>
                  </li>
                  <li>
                    <div className="img_box">
                      <figure>
                        <img src={jobIcon5} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="detail">
                      <h5>Experience</h5>
                      <p>{jobDetails?.experience}</p>
                    </div>
                  </li>
                  <li>
                    <div className="img_box">
                      <figure>
                        <img src={jobIcon6} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="detail">
                      <h5>Gender</h5>
                      <p>{jobDetails?.gender}</p>
                    </div>
                  </li>
                  <li>
                    <div className="img_box">
                      <figure>
                        <img src={jobIcon7} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="detail">
                      <h5>Qualification</h5>
                      <p>{jobDetails?.qualification}</p>
                    </div>
                  </li>
                  <li>
                    <div className="img_box">
                      <figure>
                        <img src={jobIcon8} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="detail">
                      <h5>Career Level</h5>
                      <p>{jobDetails?.career_level}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
          </>
        ) 
      }
   


      <Footer />
      <ApplyNow 
        setIsOpenModal={setIsOpenModal} 
        isOpenModal={isOpenModal} 
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        address={address}
        setAddress={setAddress}
        city={city}
        setCity={setCity}
        statedata={statedata}
        setStateData={setStateData}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        loading={loading}
        UploadResume={UploadResume}
        mediaFile={mediaFile}
        handleImageUpload={handleImageUpload}
      />

    </>
  );
}

export default JobDetails;
