import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import "../../assets/css/sign-up.css";
import { rightArrow } from '../../constant';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from "query-string";
import { toast } from 'react-toastify';
import { SetResetPassword } from '../../redux/actions/AuthActions';

function NewPassword() {
    const location = useLocation();
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const [ password , setPassword ] = useState("")
    const [ confirmPassword , setConfirmPassword ] = useState("")
    const [loading, setLoading] = useState(false);
    const value = queryString.parse(location.search);
    const token = value.token;
    console.log("token", token);

    const NewPasswordHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!password || !confirmPassword) {
          toast.error("Please Fill all fields");
          setLoading(false);
          return;
        }
        if (password !== confirmPassword) {
          toast.error("Please Enter Same password and Confirm Password");
          setLoading(false);
          return;
        }
        let data = {
          password: password,
          confirm_password: confirmPassword,
          reset_token: token,
        };
        console.log(data);
        setTimeout(async () => {
          let send = await dispatch(SetResetPassword(data, Navigate));
          setLoading(false);
          //   Navigate("/Signin");
        }, 600);
      };

  return (
   <>
    <Header />

<section className="universal_banner">
    <div className="container">
        <div className="page_details">
            <h2 className="title">RENEW PASSWORD</h2>
            <ul className="breadcrumbs">
                <li><Link to="/">HOME PAGE</Link></li>
                <li className="active">RENEW PASSWORD</li>
            </ul>
        </div>
    </div>
</section>

<section className="checkout">
    <div className="container">
        <form>
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 kip">
                    <div className="first-top-bar">
                        <h3>Password</h3>
                    </div>
                    <div className="add-new-address">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="password">New Password</label>
                                    <input 
                                        type="password" 
                                        id="NewPassword" 
                                        className="form-control"
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="ConfirmNewPassword">Confirm New Password</label>
                                    <input 
                                        type="password" 
                                        id="ConfirmNewPassword" 
                                        className="form-control"
                                        value={confirmPassword}
                                        onChange={(e)=>setConfirmPassword(e.target.value)}   
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-12 text-right">
                                        <div className="add-new-method">

                                            <button type="submit" 
                                                className="btn"
                                                onClick={(e)=>NewPasswordHandler(e)}
                                                disabled={loading}
                                            >
                                                {loading ? "Loading.." :  "SEND EMAIL"} 
                                                <img src={rightArrow} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-lg-2"></div>
            </div>
        </form>
    </div>
</section>
    <Footer />
   </>
  )
}

export default NewPassword