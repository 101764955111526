import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
	arrow,
	doordash,
	ezcater,
	filter,
	grubhub,
	gruhub,
	notFound,
	restaurentLogo,
	ubereats,
} from "../../constant";
import "../../assets/css/find-restaurent.css";
import { Link } from "react-router-dom";
import MapChart from "../../components/Map/MapChart";
import {
	GetAllRestaurants,
	GetAllRestaurantsSearch,
	GetSearchRestaurant,
} from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import { AllRestaurants } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";

function FindRestaurant() {
	const dispatch = useDispatch();
	let time = new Date();
	const [pageCount, setPageCount] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [spinLoad, setSpinLoad] = useState(false);
	const [resaturentData, setResaturentData] = useState([]);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [selectedData, setSelectedData] = useState();

	const [postalCode, setPostalCode] = useState("");
	const [localState, setLocalState] = useState("");

	const handlePageClick = async (data) => {
		console.log(data.selected);
		setCurrentPage(data?.selected + 1);
	};

	const FindRestaurants = (e = null) => {
		if (e) {
			e.preventDefault();
		}
		setSpinLoad(true);
		GetAllRestaurantsSearch(postalCode)
			.then((res) => {
				console.log(res);
				setResaturentData(res?.data?.response?.data?.data);
				dispatch(AllRestaurants(res?.data?.response?.data?.data));
				const total = res?.data?.response?.data?.total;
				const limit = res?.data?.response?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				setSpinLoad(false);
			})
			.catch((err) => {
				console.log(err);
				setSpinLoad(false);
			});
	};

	useEffect(() => {
		FindRestaurants(null);
	}, []);

	const SerachRestaurentHandler = (e = null) => {
		if (e) {
			e.preventDefault();
		}
		let data = {
			province: localState,
			search: postalCode,
		};
		GetSearchRestaurant(data)
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// useEffect(() => {
	//   SerachRestaurentHandler(null)
	// }, []);
	console.log(resaturentData);

	return (
		<>
			<Header />
			{/* <!-- Universal Banner Sec Start Here --> */}
			<section className="universal_banner">
				<div className="container">
					<div className="page_details">
						<h2 className="title">Find Restaurant</h2>
						<ul className="breadcrumbs">
							<li>
								<Link to="/">HOME PAGE</Link>
							</li>
							<li className="active">FIND A RESTAURANT</li>
						</ul>
					</div>
				</div>
			</section>
			{/* <!-- Universal Banner Sec End Here --> */}

			{/* <!-- Filter Sec Start Here --> */}
			{/* <section className="filter_sec">
				<div className="container">
					<div className="filter_box">
						<form>
							<div className="left_box">
								<div className="filter_btn">
									<h5>
										<img src={filter} className="img-fluid" /> Filter :{" "}
									</h5>
								</div>
								
							</div>
							<div className="right_box">
								<div className="serach_restaurent">
									<input
										type="text"
										placeholder="City, Postal Code, Province"
										className="form-control"
										value={postalCode}
										onChange={(e) => setPostalCode(e.target.value)}
									/>
									<label>Display results within 25 km</label>
								</div>
								<div className="button-group">
									<button className="btn" onClick={(e) => FindRestaurants(e)}>
										APPLY <img src={arrow} className="img-fluid" />
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section> */}
			{/* <!-- Filter Sec End Here --> */}

			{/* <!-- Google Map Sec Start Here --> */}
			<section className="google_map find_Res">
				<div className="container">
					{/* <MapChart 
            resaturentData={resaturentData}
          /> */}
					{/* <iframe
            src="https://www.google.com/maps/d/embed?mid=1bSMwYA9KkwCoTd3ODffhhsIvmYheJApZ&ehbc=2E312F"
            width="100%"
            height="400"
          ></iframe> */}
				</div>
			</section>
			{/* <!-- Google Map Sec End Here --> */}

			{/* <!-- Restaurent Sec Start Here --> */}
			<section className="restaurent_sec">
				<div className="container">
					<div className="row">
						{spinLoad ? (
							<>
								<div className="m-4">
									<Skeleton count={5} />
								</div>
								<div className="m-4">
									<Skeleton count={5} />
								</div>
								<div className="m-4">
									<Skeleton count={5} />
								</div>
							</>
						) : (
							<>
								{resaturentData?.length > 0 ? (
									<>
										{resaturentData?.map((item, index) => {
											return (
												<div className="col-lg-6" key={index}>
													<div className="restaurent_box">
														{/* <Link to={`/restaurant-menu/${item?.id}`}> */}
														<div className="topbar">
															<div className="logoBox">
																<figure>
																	<img src={item?.logo} className="img-fluid" />
																</figure>
															</div>
															<div className="details">
																<h4 className="name">{item?.title}</h4>
																<p className="province">
																	{/* {item?.province}, {item?.city} {item?.country} */}
																</p>
																<p
																	onClick={() =>
																		window.open(
																			`https://www.google.com/maps/place/${item?.address}`,
																			"_blank",
																		)
																	}
																	style={{ cursor: "pointer" }}
																	className="address"
																>
																	<i
																		className="fa fa-map-marker"
																		aria-hidden="true"
																	></i>{" "}
																	{item?.address}
																</p>
																<p>
																	<span className="property">Contact :</span>{" "}
																	<span className="value">{item?.phone}</span>
																</p>
																<p>
																	<span className="property">
																		Open - Close :
																	</span>{" "}
																	<span className="value">
																		{moment(item?.open_timing, "HH:mm").format(
																			"hh:mm A",
																		)}{" "}
																		to{" "}
																		{moment(item?.close_timing, "HH:mm").format(
																			"hh:mm A",
																		)}{" "}
																		{item?.open_close_days}
																	</span>
																</p>
																{/* <div className="tags">
																	<p className="partner">Partner</p>
																	<p className="dining">Casual Dining</p>
																</div> */}
															</div>
														</div>
														<div className="content_wrapper">
															{item?.ezcater_link && (
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() =>
																		window.open(
																			`${item.ezcater_link}`,
																			"_blank",
																		)
																	}
																	className="third-link"
																>
																	<img
																		src={ezcater}
																		alt=""
																		className="image-fluid"
																	/>
																</div>
															)}
															{item?.grubhub_link && (
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() =>
																		window.open(
																			`${item.grubhub_link}`,
																			"_blank",
																		)
																	}
																	className="third-link"
																>
																	<img
																		src={grubhub}
																		alt=""
																		className="image-fluid"
																	/>
																</div>
															)}
															{item?.doordash_link && (
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() =>
																		window.open(
																			`${item.doordash_link}`,
																			"_blank",
																		)
																	}
																	className="third-link"
																>
																	<img
																		src={doordash}
																		alt=""
																		className="image-fluid"
																	/>
																</div>
															)}
															{item?.uber_link && (
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() =>
																		window.open(`${item.uber_link}`, "_blank")
																	}
																	className="third-link"
																>
																	<img
																		src={ubereats}
																		alt=""
																		className="image-fluid"
																	/>
																</div>
															)}

															{/* <p>
                              <span className="property">Open - Close :</span>{" "}
                              <span className="value">
                              {item?.open_timing}{" "}to{" "}  {item?.close_timing} {" "}{item?.open_close_days}
                              </span>
                            </p>
                            <p>
                              <span className="property">Cuisines :</span>{" "}
                              <span className="value">
                                Lunch, Breakfast, Dinner
                              </span>
                            </p>

                            <p>
                              <span className="property">Contact :</span>{" "}
                              <span className="value">{item?.phone}</span>
                            </p> */}
														</div>
													</div>
												</div>
											);
										})}
									</>
								) : (
									<div className="notFound-container">
										<img
											src={notFound}
											alt="not found"
											className="image-fluid"
										/>
										<p className="mt-4">No Record Found</p>
									</div>
								)}
							</>
						)}
					</div>
					<div className="pagination-container paginations mt-5">
						<ReactPaginate
							previousLabel="<"
							nextLabel=">"
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
					</div>
				</div>
			</section>
			{/* <!-- Restaurent Sec End Here --> */}

			<Footer />
		</>
	);
}

export default FindRestaurant;
