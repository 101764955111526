import React from "react";
import Slider from "react-slick";
import { arrow, lunch1, lunch2, lunch3 } from "../../constant";

function MenuSlider(props) {
  const {setIsOpenModal} = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  const menuData = [
    {
      id: 1,
      img: lunch1,
    },
    {
      id: 2,
      img: lunch2,
    },
    {
      id: 3,
      img: lunch3,
    },
    {
      id: 4,
      img: lunch1,
    },
    {
      id: 5,
      img: lunch2,
    },
    {
      id: 6,
      img: lunch3,
    },
  ];
  return (
    <>
      <div>
        <Slider {...settings}>
          {menuData?.map((item, index) => {
            return (
              <>
                <div className="online_order_products" key={index}>
                  <div className="img_box">
                    <figure>
                      <img src={item?.img} className="img-fluid" />
                    </figure>
                  </div>
                  <div className="order-detail">
                    <h4 className="name">Venison Wellington</h4>
                    <ul className="ratings">
                      <li>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                      </li>
                    </ul>
                    <div className="price">
                      <h5 className="sale-price">$170.58</h5>
                      <h5 className="reg-price">
                        <del>$220.50</del>
                      </h5>
                    </div>
                    <div className="button-group">
                      <button
                        type="button"
                        className="btn"
                        // data-toggle="modal"
                        // data-target="#order-cart"
                        onClick={()=>setIsOpenModal(true)}
                      >
                        ADD TO CART{" "}
                        <img src={arrow} className="img-fluid" />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
      </div>
    </>
  );
}

export default MenuSlider;
