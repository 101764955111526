import React from "react";

function ServiceCard(props) {
  const { item, index } = props;
  return (
    <>
      <div className="col-lg-3 col-md-6" key={index}>
        <div className="expertise_box">
          <div className="img_box">
            <figure>
              <img src={item?.img} className="img-fluid" />
            </figure>
          </div>
          <div className="content">
            <h4>{item?.title}</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCard;
