import React from "react";

function TeamCard(props) {
  const { item, index } = props;
  return (
    <div className="col-lg-4" key={index}>
      <div className="team_box">
        <div className="img_box">
          <figure>
            <img src={item?.img} className="img-fluid" />
          </figure>
        </div>
        <div className="contentBox">
          <h4 className="name">Maxwell Rodriques</h4>
          <p className="designation">C H E F</p>
        </div>
      </div>
    </div>
  );
}

export default TeamCard;
