import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { GiPositionMarker } from "react-icons/gi";
import { useSelector } from "react-redux";

function GoogleMapComp(props) {
	const Restaurants = useSelector((state) => state.AuthReducer.restaurantsData);
	const [coords, setCoords] = useState();

	// {
	//   lat: 41.9077862, lng : -87.834445
	// }
	useEffect(() => {
		// navigator.geolocation.getCurrentPosition(
		//   ({ coords: { latitude, longitude } }) => {
		//     setCoords({ lat: latitude, lng: longitude });
		//   }
		// );
		if (Restaurants?.length > 0) {
			var lat_n = parseFloat(Restaurants[0]?.lat);
			var lng_n = parseFloat(Restaurants[0]?.lng);
			let NewLatLng = { lat: lat_n, lng: lng_n };
			console.log("aa", NewLatLng);
			setCoords(NewLatLng);
		}
	}, []);
	// console.log("coords", coords);

	// console.log(trackingData);

	// const origin = { lat: coords.lat, lng: coords.lng };
	// const destination = { lat: 24.9372, lng: 67.0423 };

	// const apiIsLoaded = (map, maps) => {
	//   const google = window.google;
	//   const directionsService = new google.maps.DirectionsService();
	//   const directionsRenderer = new google.maps.DirectionsRenderer();
	//   directionsRenderer.setMap(map);

	//   var flightPath = new google.maps.Polyline({
	//     path: { origin, destination },
	//     geodesic: true,
	//     strokeColor: "#FF0000",
	//     strokeOpacity: 1.0,
	//     strokeWeight: 2,
	//   });
	//   flightPath.setMap(google.map);

	//   directionsService
	//     .route({
	//       origin: origin,
	//       destination: destination,
	//       travelMode: google.maps.TravelMode.DRIVING,
	//     })
	//     .then((response) => {
	//       console.log({ response });
	//       directionsRenderer.setDirections(response);
	//     })
	//     .catch((err) => {
	//       console.log({ err });
	//     });
	//   //   (result, status) => {
	//   //     console.log(result);
	//   //     if (status === google.maps.DirectionsStatus.OK) {
	//   //       directionsRenderer.setDirections(result);
	//   //     } else {
	//   //       console.error(`error fetching directions ${result}`);
	//   //     }
	//   //   }
	//   // );
	// };

	const apiIsLoaded = (map, maps) => {
		const google = window.google;
		const directionsService = new google.maps.DirectionsService();
		const directionsRenderer = new google.maps.DirectionsRenderer();
		directionsRenderer.setMap(map);

		// current
		const destination = { lat: coords.lat, lng: coords.lng };

		// driver
		// const origin = {
		//   lat: parseFloat(trackingData?.data?.driver?.lat),
		//   lng: parseFloat(trackingData?.data?.driver?.lng),
		// };

		// let marker = new maps.Marker({
		//   position: { lat: origin.lat, lng: origin.lng },
		//   map,
		//   title: "Driver",
		// });
		// return marker;

		// let markerb = new maps.Marker({
		//   position: { lat: destination.lat, lng: destination.lng },
		//   map,
		//   title: "Current",
		// });
		// return markerb;

		console.log(destination);
		console.log(origin);

		// const origin = { lat: 24.8655364, lng: 67.0583857 };
		// const destination = { lat: 24.9372, lng: 67.0423 };

		// const origin = { lat: 40.756795, lng: -73.954298 };
		// const destination = { lat: 41.756795, lng: -78.954298 };

		var flightPath = new google.maps.Polyline({
			path: { origin, destination },
			geodesic: true,
			strokeColor: "#FF0000",
			strokeOpacity: 1.0,
			strokeWeight: 2,
		});
		flightPath.setMap(google.map);
		directionsService.route(
			{
				origin: origin,
				destination: destination,
				travelMode: google.maps.TravelMode.DRIVING,
			},
			(result, status) => {
				if (status === google.maps.DirectionsStatus.OK) {
					directionsRenderer.setDirections(result);
					console.log(result);
				} else {
					console.log(`error fetching directions`, result);
				}
			},
		);
	};

	// const renderPolylines = (map, maps) => {
	//   let markers = [];
	//   markers = [...markers, origin];
	//   markers = [...markers, destination];
	//   console.log(markers);
	//   let geodesicPolyline = new maps.Polyline({
	//     path: markers,
	//     geodesic: true,
	//     strokeColor: "#e4e4e4",
	//     strokeOpacity: 1.0,
	//     strokeWeight: 3,
	//   });
	//   geodesicPolyline.setMap(map);
	// };

	return (
		<>
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyCjulI5lvM_5aEkceTsSZJfYZ-DqguELN4" }}
				defaultCenter={coords}
				center={coords}
				defaultZoom={8}
				margin={[50, 50, 50, 50]}
				options={{ disableDefaultUI: true, zoomControl: true }}
				strokeColor={"#FF0000"}
				strokeWeight={2}
				// onChange={(e) => {
				//   setCoords({ lat: e.center.lat, lng: e.center.lng });
				//   // setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw });
				// }}
				// onChildClick={(child) => setChildClicked(child)}
				yesIWantToUseGoogleMapApiInternals
				// onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
				// onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
			>
				{Restaurants?.map((item, index) => {
					console.log("lt", item);
					return (
						<GiPositionMarker
							lat={item?.lat}
							lng={item?.lng}
							className="marker-points"
						/>
					);
				})}
			</GoogleMapReact>
		</>
	);
}

export default GoogleMapComp;

// AIzaSyC4kWPZyaWQTY5Hn3d_aZ896ZYBSoc_POI

{
	/* <div
lat={trackingData?.data?.driver?.lat}
lng={trackingData?.data?.driver?.lng}
>
<FontAwesomeIcon
  icon={faMapMarkerAlt}
  style={{ color: "#dc3545", fontSize: "30px" }}
/>
Driver
</div>
<div lat={coords?.lat} lng={coords?.lng}>
<FontAwesomeIcon
  icon={faTruck}
  style={{ color: "#FF9517", fontSize: "30px" }}
/>
current
</div> */
}
