import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
// import './stripePayment.scss';
import { toast } from "react-toastify";

const stripePromise = loadStripe(
  "pk_test_51LDNsIIXSseLhvkZqS3jYdzRjF32NH5UPVnZ1UD0WIfz6lEcNFBSVz9mS5TW3S0bsicnOfztLWPJYJDBa3CTuF1C00vWyczX1r"
);

const CheckoutForm = (props) => {
  const { PaymentHandler, setCardToken, cardToken,  } =props;
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    setCardToken(payload?.token?.id);
    console.log("check", payload);
    if (payload?.token?.id) {
      PaymentHandler(event);
      return;
    } else {
      toast.error("Something Went Wrong");
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  // const cardStyle = {
  //   style:{
  //     width : "100%",
  //   }
  // }
  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* <div className="stripe-card"> */}
          <CardElement
          // id="card-element"
          // options={cardStyle}
            onChange={(e) => {
              if (e.complete) {
                setPayButton(false);
              } else {
                setPayButton(true);
              }
            }}
          />
        {/* </div> */}
      
        <br />
      
        <ul className="list-inline">
          <li>
            <button
              type="submit"
              className="default-btn stripePay-Btn btn "
              disabled={!stripe || !elements || paybutton}
            >
            Payment
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};


// const options = {
//   // passing the client secret obtained from the server
//   clientSecret: 'sk_test_51LDNsIIXSseLhvkZx5H3UlJTjGnGEcIJD73SHtHHarwBlPER3uZAEAUdsstrUKfklI5hG7WbcKnDMuE4JcvXvjNS00wMzAEtmg',
// };
// const publicStripePromise = loadStripe('pk_test_51LDNsIIXSseLhvkZqS3jYdzRjF32NH5UPVnZ1UD0WIfz6lEcNFBSVz9mS5TW3S0bsicnOfztLWPJYJDBa3CTuF1C00vWyczX1r');



const StripeForm = (props) => {
  const { PaymentHandler, setCardToken, cardToken } =props;
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm 
        PaymentHandler={PaymentHandler}
        setCardToken={setCardToken}
        cardToken={cardToken}
      />
    </Elements>
  );
};

export default StripeForm;
