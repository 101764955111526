import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import FoodSlider from "../../components/Slider/FoodSlider";
import {
	breakfast1,
	chicago,
	clientnameTop,
	doordash,
	ezcater,
	facebook,
	grubhub,
	instagram,
	lunch1,
	plate2,
	rec1,
	rightArrow,
	separator,
	testimonial1,
	testimonial2,
	testimonial3,
	ubereats,
	waffles1,
	yelp,
} from "../../constant";
import moment from "moment";
import {
	GetAllRecomendation,
	GetAllReviews,
	GetHomeSlider,
	GetSettingPages,
} from "../../network/Network";
import TestimonialSlider from "../../components/Slider/TestimonialSlider";
import Slider from "react-slick";
import GoogleMapComp from "../../components/Map/GoogleMapComp";

function Home() {
	const navigate = useNavigate();
	const BlogReduxData = useSelector((state) => state.AuthReducer.blogs);

	const [currentPage, setCurrentPage] = useState(1);
	const [reviewsData, setReviewsData] = useState([]);
	const [recomendationData, setRecomendationData] = useState([]);
	const [settingData, setSettingData] = useState([]);
	const [sliderData, setSliderData] = useState([]);

	const settings = {
		// dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		// autoplay: true,
		// autoplaySpeed: 2000,
		arrows: true,
	};

	useEffect(() => {
		GetAllReviews(currentPage)
			.then((res) => {
				setReviewsData(res?.data?.response?.data?.data);
				// setSpinLoad(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [currentPage]);

	useEffect(() => {
		GetAllRecomendation()
			.then((res) => {
				setRecomendationData(res?.data?.response?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		GetSettingPages()
			.then((res) => {
				setSettingData(res?.data?.response?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	// useEffect(() => {
	// 	GetHomeSlider()
	// 		.then((res) => {
	// 			console.log(res);
	// 			setSliderData(res?.data?.response?.data);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// }, []);

	return (
		<>
			<Header />
			{/* <!-- ======= Slider Section ======= --> */}
			<section id="stack-slider" className="hero d-flex align-items-center">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 d-flex flex-column justify-content-center align-self-center">
							<p className="text-white text-center wow animate__animated  animate__backInDown">
							Welcome
							</p>
							<h1 className="text-white text-center wow animate__animated  animate__backInDown">
							Mr. Stacks is a family owned restaurant serving a wide variety of Breakfast and Lunch items sure to please your taste buds.
							</h1>
							<div data-aos="fade-up" data-aos-delay="600">
								<div className="text-center text-lg-start">
									{/* <!-- <a href="javascript:void(0);"
                            className="stack-button btn wow animate__animated  animate__fadeInLeft scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                            <span>ORDER ONLINE <img src="img/right-arrow.svg" alt=""></span>
                            <i className="bi bi-arrow-right"></i>
                        </a> --> */}
									{/* <Link to={`/menu`}
                      state={{ data: " " }}
                      className="btn mt-3"
                  >
                    ORDER ONLINE <img src={rightArrow} alt="" />
                  </Link> */}
									<div className="banner-img">
										<img
											className="w-50 img-fluid"
											src={plate2}
											className="wow animate__animated  animate__backInDown"
											alt="plate"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <!-- ======= Diet Section ======= --> */}
			<section id="diet-section">
				<div className="container">
					<div className="row">
						<FoodSlider sliderData={sliderData} />
					</div>

					{/* <!-- <div className="row mt-5">
                <div className="col-md-4">
                    <div className="img_box wow animate__ animate__fadeInLeft animated"
                        style="visibility: visible; animation-name: fadeInLeft;">
                        <figure><img className="img-fluid" src="img/exp1.jpg"></figure>
                        <div className="shape"></div>
                    </div>

                </div>
                <div className="col-md-4 text-center">
                    <span className="mt-1">O U R S T O R Y</span>
                    <h2 className="mt-2">Delightful <br>
                        Experience</h2>
                    <img className="mt-2" src="img/separator.png">
                    <h4 className="mt-2">“Everything is possible. We’ll make
                        your stay unforgettable.”</h4>
                    <p className="mt-2">From traditional flavours influenced by local culture to
                        gastronomic delights that trot the world, we have always raised
                        the standard of hospitality through Responsible Luxury.</p>
                    <img className="mt-2 img-fluid" src="img/signature.png">
                    <p className="mt-2 pull">JOHN JOSEFLER / <span className="wall">FOUNDER</span> </p>
                </div>
                <div className="col-md-4">
                    <div className="img_box wow animate__ animate__fadeInLeft"
                        style="visibility: visible; animation-name: fadeInLeft;">
                        <figure><img className="img-fluid" src="img/exp2.jpg"></figure>
                        <div className="shape"></div>
                    </div>

                </div>
            </div> --> */}
				</div>
			</section>
			{/* <!-- End Diet Section --> */}

			<section id="recommendation-section">
				<div className="container">
					{/* <div className="row pb-5 mb-5">
						<div className="col-md-12 text-center wow animate__animated animate__fadeInLeft">
							<p className="pill text-white">OUR TOP LINE </p>
							<h1 className="text-white">
								What we recommend for first-time visitors
							</h1>
							<img src={separator} />
						</div>
						{recomendationData?.map((item, index) => {
							return (
								<div className="col-md-4" key={index}>
									<div className="recommendation1 recommendation text-center">
										<img className="img-fluid" src={item?.image} />
										<div className="recommendation-text text-center">
											<h4 className="font-weight-bold">{item?.title}</h4>
											<span className="category-sing-product">
												{item?.description}
											</span>
											<h6 className="price">${item?.price}</h6>
										</div>
									</div>
								</div>
							);
						})}
					</div> */}

					<div className="row mt-5 pt-5">
						<div className="col-md-12 text-center wow animate__animated animate__fadeInLeft">
							<p className="pill text-white">OUR EFFORTS</p>
							<h1 className="text-white">{settingData?.description}</h1>
							<img src={separator} />
						</div>
						<div className="col-md-12">
							<div className="google_map">
								<GoogleMapComp />
							</div>
							{/* <div className="locationsDv">
								<div className="map_img">
									<figure>
										<img src={chicago} className="img-fluid" />
									</figure>
								</div>
								<div className="map_location">
									<div className="restaurent_detail chicago_detail">
										<div
											className="chicago_point points"
											style={{ cursor: "pointer" }}
											onClick={() =>
												window.open(
													`https://www.google.com/maps/place/8349%20West%20North%20Avenue,%20Melrose%20Park,%20IL%2060160,%20USA`,
													"_blank",
												)
											}
										>
											<i className="fa fa-map-marker" aria-hidden="true"></i>
										</div>
									</div>
									<div className="restaurent_detail tinley_detail">
										<div
											className="tinley_point points"
											style={{ cursor: "pointer" }}
											onClick={() =>
												window.open(
													`https://www.google.com/maps/place/222%20Dixie%20Hwy,%20Chicago%20Heights,%20IL%2060411,%20USA`,
													"_blank",
												)
											}
										>
											<i className="fa fa-map-marker" aria-hidden="true"></i>
										</div>
									</div>
									<div className="restaurent_detail naperville_detail">
										<div
											className="naperville_point points"
											style={{ cursor: "pointer" }}
											onClick={() =>
												window.open(
													`https://www.google.com/maps/place/3064%20South%20Cicero%20Avenue,%20Cicero,%20IL%2060804,%20USA`,
													"_blank",
												)
											}
										>
											<i className="fa fa-map-marker" aria-hidden="true"></i>
										</div>
									</div>
									<div
										className="restaurent_detail cicero_detail"
										style={{ cursor: "pointer" }}
										onClick={() =>
											window.open(
												`https://www.google.com/maps/place/7164%20183rd%20St,%20Tinley%20Park,%20IL%2060477,%20USA`,
												"_blank",
											)
										}
									>
										<div
											className="cicero_point points"
											style={{ cursor: "pointer" }}
											onClick={() =>
												window.open(
													`https://www.google.com/maps/place/8349%20West%20North%20Avenue,%20Melrose%20Park,%20IL%2060160,%20USA`,
													"_blank",
												)
											}
										>
											<i className="fa fa-map-marker" aria-hidden="true"></i>
										</div>
									</div>
									<div
										className="restaurent_detail melrose_detail"
										style={{ cursor: "pointer" }}
										onClick={() =>
											window.open(
												`https://www.google.com/maps/place/8349%20West%20North%20Avenue,%20Melrose%20Park,%20IL%2060160,%20USA`,
												"_blank",
											)
										}
									>
										<div className="melrose_point points">
											<i className="fa fa-map-marker" aria-hidden="true"></i>
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End Recommendation Section --> */}

			{/* <!-- ======= Testimonial Section ======= --> */}
			<section id="testimonial-section">
				<div className="container">
					<div className="row">
						{/* <div className="col-md-12 text-center wow animate__animated animate__fadeInLeft">
							<p className="sub-title">HAPPY CUSTOMER</p>
							<h1>Client Testimonials</h1>
							<img src={separator} />
						</div>
						<div className="col-md-12 testi-cal">
							<div className="container">
								<div>
									<Slider {...settings}>
										{reviewsData?.map((item, index) => {
											console.log(item);
											return <TestimonialSlider item={item} index={index} />;
										})}
									</Slider>
								</div>
							</div>
						</div> */}
						
						{/* <div className="col-md-12 text-center">
							<div className="heading_wrapper mb-5">
								<p className="blog-subtitle">OUR RECENT BLOG</p>
								<h1>Blogs</h1>
								<img src={separator} />
							</div>
						</div>
						{BlogReduxData?.map((item, index) => {
							return (
								<>
									{index < 3 ? (
										<div
											className="col-md-4 blog-content"
											key={index}
											onClick={() => navigate(`/blog-details/${item?.id}`)}
										>
											<div className="img_box wow animate__ animate__fadeInLeft animated">
												<figure>
													<img className="img-fluid" src={item?.image} />
												</figure>
												<div className="shape"></div>
											</div>

											<p>
												{moment(item?.updated_at).format("MMMM Do YYYY")}{" "}
												{item?.added_by}{" "}
											</p>
											<h5>{item?.title}</h5>
										</div>
									) : null}
								</>
							);
						})} */}
					</div>
				</div>
			</section>
			{/* <!-- End Testimonial Section --> */}

			{/* <!-- work with us section starts here --> */}
			{/* <section className="work-with-us">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="work-bg-wrapper">
								<div className="work-content-wrapper text-center">
									<h6>WE'RE HIRING</h6>
									<h3>
										We're always looking for talented individuals for our
										restaurants
									</h3>
									<h2>WE ARE HIRING</h2>
									<Link to="/careers" id="btn" className="btn">
										VIEW JOBS <img src={rightArrow} alt="" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* <!-- work with us section starts here --> */}

			<Footer />
		</>
	);
}

export default Home;
