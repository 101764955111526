import './assets/css/Style.css';
import './assets/css/main-banner.css';
import './assets/css/Custom.css';
import PublicRoutes from './routes/PublicRoutes';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from 'react';
import { GetAllAddress, GetAllCategories, GetAllRestaurants, GetBlogs, GetPagesApi } from './network/Network';
import { AllAddress, AllRestaurants, Blogs, PagesData } from './redux/actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css'
import StripeForm from './components/Stripe/StripeForm';

function App() {
  const dispatch = useDispatch()
  const Token = useSelector((state) => state.AuthReducer.token);
  const [currentPage, setCurrentPage] = useState(1);
  const [ blogData, setBlogData ] = useState([])

  useEffect(() => {
    GetAllRestaurants()
      .then((res) => {
        dispatch(AllRestaurants(res?.data?.response?.data?.data))
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  // PAGES API 
  useEffect(() => {
      GetPagesApi()
      .then((res) => {
        dispatch(PagesData(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    GetBlogs(currentPage)
    .then((res) => {
      dispatch(Blogs(res?.data?.response?.data?.data));
    })
    .catch((err) => {
      console.log(err);
    });
}, [currentPage]);
  
useEffect(() => {
  GetAllAddress(Token)
    .then((res) => {
      dispatch(AllAddress(res?.data?.response?.data?.data));
    })
    .catch((err) => {
      console.log(err);
    });
}, []);



  return (
    <>
     <PublicRoutes />
     <ToastContainer />
    </>
  );
}

export default App;
