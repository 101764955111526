import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/reviews.css";
import { review1, review2, reviewSep, separator } from "../../constant";
import { GetAllReviews, PostRating } from "../../network/Network";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import queryString from 'query-string'

function FeedBackForm() {
  const Navigate = useNavigate();
  const location = useLocation()

  const value=queryString.parse(location.search);
    const Token=value.token;
    console.log('Token',Token)//123

//   const Token = useSelector((state) => state.AuthReducer.token);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRating = (rate) => {
    if (rate == "20") {
      setRating(1);
    } else if (rate == "40") {
      setRating(2);
    } else if (rate == "60") {
      setRating(3);
    } else if (rate == "80") {
      setRating(4);
    } else {
      setRating(5);
    }
    // setRating(rate);
  };

    const SubmitRating = (e) => {
      e.preventDefault();
      setLoading(true);
      if (!rating || !comment) {
        toast.error("Please Enter All Fields");
        setLoading(false);
        return;
      }
      let data = {
        token:Token,
        rating: rating,
        comments: comment,
      };
      PostRating(data)
        .then((res) => {
          setLoading(false);
          console.log(res);
          toast.success("Successfully send");
          setRating("");
          setComment("");
          Navigate("/");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setRating("");
          setComment("");
        });
    };

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">Reviews</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">REVIEW</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {/* <!-- Testimonials Sec Start Here --> */}
      <section className="testimonials_sec">
        <div className="container">
          <div className="heading_wrapper">
            <h5 className="sub-title">HAPPY CUSTOMER</h5>
            <h2 className="title">Review Form</h2>
            <div className="seprator">
              <figure>
                <img src={separator} className="img-fluid" />
              </figure>
            </div>
          </div>
          <div className="feedback-form">
            <div className="rating-inner-cont">
              <Rating onClick={handleRating} ratingValue={rating} />
            </div>
            <div>
              <textarea
                placeholder="Enter your Comments"
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div className="btn-container">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => SubmitRating(e)}
                disabled={loading}
              >
                {loading ? "Loading.." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Testimonials Sec End Here --> */}
      <Footer />


    </>
  );
}

export default FeedBackForm;
