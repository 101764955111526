import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/reviews.css";
import { notFound, review1, review2, reviewSep, separator, yelp, google } from "../../constant";
import { GetAllReviews } from "../../network/Network";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

function Reviews() {
	const [pageCount, setPageCount] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [spinLoad, setSpinLoad] = useState(false);
	const [reviewsData, setReviewsData] = useState([]);

	const handlePageClick = async (data) => {
		console.log(data.selected);
		setCurrentPage(data?.selected + 1);
	};

	useEffect(() => {
		setSpinLoad(true);
		GetAllReviews(currentPage)
			.then((res) => {
				console.log(res);
				setReviewsData(res?.data?.response?.data);
				const total = res?.data?.response?.data?.total;
				const limit = res?.data?.response?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				setSpinLoad(false);
			})
			.catch((err) => {
				console.log(err);
				setSpinLoad(false);
			});
	}, [currentPage]);
	return (
		<>
			<Header />
			{/* <!-- Universal Banner Sec Start Here --> */}
			<section className="universal_banner">
				<div className="container">
					<div className="page_details">
						<h2 className="title">Reviews</h2>
						<ul className="breadcrumbs">
							<li>
								<Link to="/">HOME PAGE</Link>
							</li>
							<li className="active">REVIEWS</li>
						</ul>
					</div>
				</div>
			</section>
			{/* <!-- Universal Banner Sec End Here --> */}

			{/* <!-- Testimonials Sec Start Here --> */}
			<section className="testimonials_sec restaurent_sec">
				<div className="container">
					<div className="heading_wrapper">
						{/* <h5 className="sub-title">HAPPY CUSTOMER</h5> */}
						<h2 className="title">Leave us a Review</h2>
						<div className="seprator">
							<figure>
								<img src={separator} className="img-fluid" />
							</figure>
						</div>
					</div>

					<div className="container">
					<div className="row">
						{spinLoad ? (
							<>
								<div className="m-4">
									<Skeleton count={5} />
								</div>
								<div className="m-4">
									<Skeleton count={5} />
								</div>
								<div className="m-4">
									<Skeleton count={5} />
								</div>
							</>
						) : (
							<>
								{reviewsData?.length > 0 ? (
									<>
										{reviewsData?.map((item, index) => {
											return (
												<div className="col-lg-6" key={index}>
													<div className="restaurent_box">
														{/* <Link to={`/restaurant-menu/${item?.id}`}> */}
														<div className="topbar">
															<div className="logoBox">
																<figure>
																	<img src={item?.logo} className="img-fluid" />
																</figure>
															</div>
															<div className="details">
																<h4 className="name">{item?.name}</h4>
																<p className="province">
																</p>
															</div>
														</div>
														<div className="content_wrapper content_wrapper2">
															{item?.yelp_link && (
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() =>
																		window.open(
																			`${item.yelp_link}`,
																			"_blank",
																		)
																	}
																	className="third-link"
																>
																	<img
																		src={yelp}
																		alt=""
																		className="image-fluid"
																	/>
																</div>
															)}
															{item?.link && (
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() =>
																		window.open(
																			`${item.link}`,
																			"_blank",
																		)
																	}
																	className="third-link"
																>
																	<img
																		src={google}
																		alt=""
																		className="image-fluid"
																	/>
																</div>
															)}
															
														</div>
													</div>
												</div>
											);
										})}
									</>
								) : (
									<div className="notFound-container">
										<img
											src={notFound}
											alt="not found"
											className="image-fluid"
										/>
										<p className="mt-4">No Record Found</p>
									</div>
								)}
							</>
						)}
					</div>
					{/* <div className="pagination-container paginations mt-5">
						<ReactPaginate
							previousLabel="<"
							nextLabel=">"
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
					</div> */}
				</div>
				</div>
			</section>
			{/* <!-- Testimonials Sec End Here --> */}
			<Footer />
		</>
	);
}

export default Reviews;
