import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { arrow, breakfast2 } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function AddToCartModal(props) {
  const { setIsOpenModal, isOpenModal,selectedData, 
    Increment,
    Decrement, quantity,setQuantity,AddToCartHandler,AddsOnHandler} = props;
    const Navigae = useNavigate()
  console.log(selectedData)

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="Addtocart-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModal(false)}
        />
        <div className="inner-content">
        <div
        id="order-cart"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="product-details-box">
                <div className="product_detail">
                  <div className="img_box">
                    <figure>
                      <img src={selectedData?.image} className="img-fluid" />
                    </figure>
                  </div>
                  <div className="content">
                    <h4 className="title">{selectedData?.title}</h4>
                    <p>
                     {selectedData?.description}
                    </p>
                  </div>
                </div>
                <div className="all_products">
                    {
                        selectedData?.extras?.map((item,index)=>{
                          console.log(item,'zzzzzzzzzzzzzzzzzzzzzz');
                            return(
                                <div className="cart-porduct" key={index}>
                                <div className="topbar">
                                  <div>
                                    <input 
                                      type="checkbox" 
                                      id={index} 
                                      className="mr-2"
                                      value={item?.id}
                                      // value={item}
                                      onChange={AddsOnHandler}
                                    />
                                    <label>{item?.name}</label>
                                  </div>
                                  <label for="frst">${item?.price}</label>
                                  <p className="tag">OPTIONAL</p>
                                </div>
                                <div className="checkboxes">              
                                  {/* <div className="form-group">
                                    <input type="checkbox" id="thrd" />
                                    <label for="thrd">Lorem ipsum</label>
                                  </div> */}
                                </div>
                              </div>
                            )
                        })
                    }
                </div>
                <div className="actions_btn">
                  <div className="quantity_dv">
                    <div className="FLnex">
                      <button className="removeCount" getid="countVal1"
                         onClick={() => Decrement(selectedData?.id)}
                         disabled={quantity < 2}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </button>
                      <p className="qty-num">{quantity}</p>
                      {/* <input type="number" value="01" id="countVal1" /> */}
                      <button 
                        className="addCount" 
                        getid="countVal1"
                        onClick={() => Increment(selectedData?.id)}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div className="price">
                    <h3>${selectedData?.price}</h3>
                  </div>
                </div>
                <div className="button-group">
                  <button 
                    className="btn"
                    onClick={() => AddToCartHandler(selectedData)}
                  >
                    Add TO CART <img src={arrow} className="img-fluid" />
                  </button>
                  <button className="btn ml-2"
                    onClick={()=>Navigae('/your-cart')}
                  >
                    CHECKOUT <img src={arrow} className="img-fluid" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </Modal>
    </>
  );
}

export default AddToCartModal;
