import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/your-shopping-cart.css";
import { notFound, plate1, plate2, rightArrow } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DecrementProductQuantity,
  deleteCartItem,
  IncrementProductQuantity,
} from "../../redux/actions/CartActions";
import { toast } from "react-toastify";
import { GetDiscount } from "../../network/Network";

function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CartData = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);

  const [subTotal, setSubTotal] = useState(0);
  const [cartData, setCartData] = useState([CartData]);
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState("");
  const [couponData, setCouponData] = useState([]);
  const [addonTotal, setAddonTotal] = useState();
  const [addsOnPrice, setAddsOnPrice] = useState();
  const [priceTotalAdd, setPriceTotalAdd] = useState(0);

  const Increment = (id) => {
    dispatch(IncrementProductQuantity(id));
  };
  const Decrement = (id) => {
    dispatch(DecrementProductQuantity(id));
  };

  const RemoveCartItem = (id) => {
    const items = cartData;
    let currentItem = id;
    if (items.length > 0) {
      setCartData(
        items.filter((item, index) => item?.productitem?.id !== currentItem)
      );
      dispatch(deleteCartItem(currentItem));
      TotalAmount()
      //   setPostList(items.filter((item, index) => index !== currentItem));
    }
  };

  const TotalAmount = () => {
    let i,j;
    let total = 0;
    let price = 0;
    let GrandTotal = 0;
    CartData?.map((val,key)=>{
      let price = parseInt(val?.price) * parseInt(val?.quantity);
      val?.addsOn?.map((addOn,key)=>{
        console.log("zzz", addOn?.price )
        price += parseInt(addOn?.price);
      });
      total += price;
      console.log("Cart Loop", total )
    })
    // for (i = 0; i < CartData.length; i++) {
    //   console.log("cart", CartData[i]?.price);
    //   total = total + CartData[i]?.price * CartData[i]?.quantity;
    // }
    // GrandTotal = total + price
    setSubTotal(total);
  }

  useEffect(() => {
    TotalAmount();
    console.log("CartData", CartData);
  }, [CartData]);

  const LoginHandler = (e) => {
    e.preventDefault();
    toast.info("Please Login");
    navigate("/login");
  };


  



  
  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">YOUR SHOPPING CART</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">YOUR SHOPPING CART</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {/* <!-- add to cart sec starts here --> */}
      <section className="add-to-cart">
        <div className="container">
          <div className="row rowt">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-7">
                  <div className="cart-items-wrapper">
                    {CartData?.length > 0 ? (
                      <>
                        {CartData?.map((item, index) => {
                          console.log(item);
                          return (
                            <>
                              <div className="single-cart-wrapper mb-4">
                                <div className="single-cart-img-wrapper">
                                  <figure>
                                    <img
                                      src={item?.productitem?.image}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </figure>
                                </div>
                                <div className="single-cart-content-wrapper">
                                  <h4>{item?.productitem?.title}</h4>
                                  <p>{item?.productitem?.description}</p>
                                  <div className="syrup-wrapper">
                                    <h5>Syrup</h5>
                                    <ul>
                                      {item?.addsOn?.map((addOnItem, index) => {
                                        return (
                                          <li key={index}>
                                            <i className="fa fa-check"></i>
                                            {addOnItem?.name} -- ${addOnItem?.price}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                  <div className="number">
                                    <span
                                      className="minus"
                                      onClick={() => Decrement(item?.id)}
                                    >
                                      -
                                    </span>
                                    <div className="qty-num">
                                      {item?.quantity}
                                    </div>
                                    {/* <input type="text" value="01" /> */}
                                    <span
                                      className="plus"
                                      onClick={() => Increment(item?.id)}
                                    >
                                      +
                                    </span>
                                  </div>
                                  <h6 className="qty-total">
                                    <span className="total-price">
                                      Quantity : {item?.quantity}
                                    </span>{" "}
                                    <span className="total-price">
                                      Price : $
                                      {/* {
                                        item?.addsOn?.map((itemIn,index)=>{
                                          return(
                                            // setAddsOnPrice(addsOnPrice+itemIn?.price)
                                            
                                          )
                                        })
                                      } */}
                                      {item?.quantity *
                                        item?.productitem?.price}
                                    </span>
                                  </h6>
                                </div>
                                <button
                                  onClick={() =>
                                    RemoveCartItem(item?.productitem?.id)
                                  }
                                >
                                  <i className="fa fa-close"></i>
                                </button>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <div className="notFound-container">
                        <img
                          src={notFound}
                          alt="not found"
                          className="image-fluid"
                        />
                        <p className="mt-4">No Record Found</p>
                      </div>
                    )}

                    <div className="button-wrapper d-flex justify-content-between">
                      {/* <button onclick="location.href=''" className="btn up-btn">
                        UPDATE CART <img src={rightArrow} alt="" />
                      </button> */}
                      <button
                        onClick={() => navigate("/")}
                        className="btn up-btn"
                      >
                        CONTINUE SHOPPING <img src={rightArrow} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 align-self">
                  <div className="order-summry-wrapper">
                    <div className="summary-content-wrapper">
                      <h4>Order Summary</h4>
                      {/* <h5>Credits Earned : 400PTS</h5> */}
                      {/* <h5>Delivery Charges : $30.25</h5> */}
                      <h5>
                        Sub Total : $
                        {couponData?.discount
                          ? subTotal - couponData?.discount
                          : subTotal}
                      </h5>
                      <p className="mb-4">
                        Taxes and discounts will be calculated at checkout
                      </p>
                      <div className="delievery-btn-wrapper mb-4">
                        {isLogin ? (
                          <>
                            {
                              CartData?.length > 0 ?(
                                <Link
                                to={`/checkout`}
                                state={{
                                  data: {
                                    CartData: CartData,
                                    total: subTotal,
                                    couponData: couponData,
                                  },
                                }}
                                className="btn"
                              >
                                Proceed to checkout <img src={rightArrow} alt="" />
                              </Link>
                              ) : (null)
                            }
                          </>
                        ) : (
                          <button
                            className="btn"
                            onClick={(e) => LoginHandler(e)}
                          >
                            Proceed to checkout <img src={rightArrow} alt="" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- add to cart sec ends here --> */}
      <Footer />
    </>
  );
}

export default Cart;
