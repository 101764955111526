import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/blog.css";
import { arrow, blog1, blog2, latest1, notFound, search } from "../../constant";
import BlogCard from "../../components/Card/BlogCard";
import { GetBlogs, GetLatestBlogs } from "../../network/Network";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import moment from "moment";

function Blog() {
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [searchBlog, setSearchBlog] = useState();
  const [latestBlogData, setLatestBlogData] = useState([]);
  

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  const SearchHandler = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    GetBlogs(currentPage, searchBlog)
      .then((res) => {
        console.log(res);
        setBlogData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    SearchHandler(null);
  }, [currentPage]);


    useEffect(() => {
        GetLatestBlogs()
        .then((res) => {
        console.log(res);
        setLatestBlogData(res?.data?.response?.data?.data);
        })
        .catch((err) => {
        console.log(err);
        });
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  
  return (
    <>
      <Header />
      {/* <!-- Universal Banner Sec Start Here --> */}
      <section className="universal_banner">
        <div className="container">
          <div className="page_details">
            <h2 className="title">BLOG</h2>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">HOME PAGE</Link>
              </li>
              <li className="active">BLOG</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner Sec End Here --> */}

      {/* <!-- Blogs Sec Start Here --> */}
      <section className="blog_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {blogData?.length > 0 ? (
                <>
                  {blogData?.map((item, index) => {
                    return <BlogCard item={item} index={index} />;
                  })}
                </>
              ) : (
                <div className="notFound-container">
                  <img src={notFound} alt="not found" className="image-fluid" />
                  <p className="mt-4">No Record Found</p>
                </div>
              )}

              <div>
                <div className="pagination-container paginations mt-5">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="vertical_line"></div>
              <div className="sidebar">
                <div className="search_box">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    value={searchBlog}
                    onChange={(e) => setSearchBlog(e.target.value)}
                  />
                  <button type="submit" onClick={(e) => SearchHandler(e)}>
                    <img src={search} className="img-fluid" />
                  </button>
                </div>
                {/* <div className="blogs_category">
                        <div className="head">
                            <h4>BLOG CATEGORIES</h4>
                        </div>
                        <ul className="categories">
                            <li><a href="#">Recipes <span className="count">(3)</span></a></li>
                            <li><a href="#">News <span className="count">(3)</span></a></li>
                            <li><a href="#">Floor <span className="count">(3)</span></a></li>
                        </ul>
                    </div> */}
                <div className="latest_post mt-3">
                  <div className="head">
                    <h4>LATEST POSTS</h4>
                  </div>
                  {latestBlogData?.map((item, index) => {
                    return (
                        <>
                            {
                                index < 3 ?(
                                    <div className="post_box" key={index}>
                                    <div className="img_box">
                                      <figure>
                                        <img src={item?.image} className="img-fluid" />
                                      </figure>
                                    </div>
                                    <div className="details">
                                      <h4 className="name">
                                        <Link to={`/blog-details/${item?.id}`}>
                                          {item?.title}
                                        </Link>
                                      </h4>
                                      <div className="dates">
                                        <p className="date">
                                          {moment(item?.updated_at).format("MMMM Do YYYY")}{" "}
                                        </p>
                                        <p className="posted_by"> {item?.added_by} </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (null)
                            }
                        </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Blogs Sec End Here --> */}
      <Footer />
    </>
  );
}

export default Blog;
